import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import HttpsRedirect from 'react-https-redirect';
import {createStore,applyMiddleware,compose } from 'redux';
import {allReducers} from './reducers';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(allReducers, composeEnhancers(applyMiddleware(thunk)))


ReactDOM.render(

  <HttpsRedirect>
    
   <Provider store={store}>

    <App />
    </Provider>
   
    </HttpsRedirect>
  
,
  document.getElementById('root')
);

