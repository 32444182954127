import React from 'react';




export const Foot = () => {
    var date = new Date().getFullYear();
    return(
      <div className="container-fluid" style={{padding:0,position:'relative'}}>
          <p style={{padding:'12px', width:'100%' ,textAlign:'center',color:'white',background:'#006aa5',margin: '0'}}>
          Copyright © {date} GharPeShiksha.com ( Tenali Education Academy Pvt. Ltd.) | All Rights Reserved
          </p>
      </div>
    )
    
    }
    