/*eslint-disable */
import React,{useState, useEffect} from 'react';
import {Typography,Card, Grid, CardMedia, CardContent, Button } from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';  
import SendIcon from '@material-ui/icons/Send';
import  {Reload1} from '../section/socket1';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import ForumIcon from '@material-ui/icons/Forum';
import AppBar from '@material-ui/core/AppBar';  
import Tabs from '@material-ui/core/Tabs';  
import Tab from '@material-ui/core/Tab';  
import {Link} from 'react-router-dom';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Box from '@material-ui/core/Box';  
import './vid.css'





export  function VideoLive(props) {  
  
  const [data, setdata]=useState([]);
  const {location, match} = props;
  const baseurl = process.env.REACT_APP_BASE_URL;



  // const [size, set] =useState(9);

  let name = localStorage.getItem("tname");

let phone = localStorage.getItem("tphone");
let tokenn = localStorage.getItem("key");
const [totalstudent, settotalstudent]=useState();
const [save, setsave]=useState({checked: false });


console.log(save);

useEffect(()=>{
  
  


  const abortController = new AbortController();
  const signal = abortController.signal;
    const fetch = async () => {
  



      const res0 = await axios.post(`${baseurl}/OnlineCourseTutorDashboard/getPublicChatStatus`, null,{
        signal:signal,
              
               params: {
                classroomId: match.params.id
                   
                 }
             });

             console.log(res0.data,'loadcstatus');
             if(res0.data.PublicChatStatus==='disable'){
              setsave({checked:true})
             }
 

      const params = new URLSearchParams()
      params.append('roomid', match.params.id)
     
     
      const res = await axios.post('https://live.gharpeshiksha.com/getChat', params);
      setdata(res.data)

            
                  
                    
                      
         

      const res1 = await axios.get(`https://live.gharpeshiksha.com/getCurrentUsersCount?roomid=${match.params.id}`);
      settotalstudent(res1.data)
      Reload1(res1.data);
    
    }
    fetch();
    
   
 
   
   
  return function cleanup(){
    abortController.abort()
  }

 
},[]);




async function Enable(){
const res = await axios.post(`${baseurl}/OnlineCourseTutorDashboard/setPublicChatStatus`, null,{
  // signal:signal,
        
         params: {
          classroomId: match.params.id,
          status : 'enable'
           }
       });
    console.log(res);
      }


      async function Disable(){
        const res = await axios.post(`${baseurl}/OnlineCourseTutorDashboard/setPublicChatStatus`, null,{
          // signal:signal,
                
                 params: {
                  classroomId: match.params.id,
                  status : 'disable'
                   }
               });
               console.log(res);

              }

let COLORS = [
  '#e21400', '#91580f', '#f8a700', '#f78b00',
  '#58dc00', '#287b00', '#a8f07a', '#4ae8c4',
  '#3b88eb', '#3824aa', '#a700ff', '#d300e7'
];
const getUsernameColor = (username) => {
  // Compute hash code
  let hash = 7;
  for (let i = 0; i < username.length; i++) {
     hash = username.charCodeAt(i) + (hash << 5) - hash;
  }
  // Calculate color
  let index = Math.abs(hash % COLORS.length);
  return COLORS[index];
}



const Set=(e)=>{
  setsave({checked: !save.checked }
    )
  
  if(save.checked==true){
  
    Enable()
  }else{

    Disable()
  }
}


  return (  


<>




  <Grid style={{margin:"0",width:'100%'}} item xs={12} item md={3}> 


  <Card style={{width:'100%', position:'relative'}}>

<div style={{minHeight: '13em',    background: '#e2e2e2'}}> 
  <table className="visible">
          <tbody><tr>
              <td style={{textAlign: 'right'}}>
                {/* <input type="text" id="conference-name" placeholder="Broadcast Name" /> */}
              </td>
              <td>
                {/* <button id="start-conferencing">New Broadcast</button> */}
              </td>
            </tr>
          </tbody></table>
        <table id="rooms-list" className="visible" />
        <table className="visible">
          <tbody><tr>
              <td style={{textAlign: 'center'}}>
                <h2>
                </h2>
              </td>
            </tr>
          </tbody></table>
          
        <div id="participants" ></div>
        </div>
<ul className="pages" style={{    height: 'auto'}}>
<li className="chat">
<div className="chatArea" style={{ paddingBottom: '0'}}>
<ul className="messages" style={{ maxHeight: '29em'}}>
  <>

   <h4 style={{textAlign:'center'}}> <span className='total' >{totalstudent}</span>  Student Watching</h4>
   
  
   
{(!data.length) ? null : data.map((dat,id)=>
        <li key={id} className="message" >
          <span className="username" style={{color:getUsernameColor(dat.name)}} >{dat.name}</span>
          <span className="messageBody">{dat.message}</span></li>
        )}
</>
</ul>
</div>

<div style={{display:'flex', flexDirection:'row-reverse'}}> 
<Button  className="sendmsg"  variant="contained" type="button"  component="label"   startIcon={<SendIcon style={{marginLeft:'12px'}} />} style={{minWidth:'20%',padding:'17px 12px',boxShadow:'none',position:'relative', bottom:'0', right:'0'}}>

</Button>
<input   style={{position: 'relative'}} className="inputMessage" placeholder="Type here..."/>


</div>
</li>
<li className="login page">
<div className="form">
<h3 className="title">What's your nickname?</h3>
<input className="usernameInput" type="text" maxLength="14" defaultValue={name} />
<input hidden className="phoneInput" type="text" maxLength="14" defaultValue={phone} />
<input hidden className="roomInput" type="text" maxLength="14" defaultValue={match.params.id} />
</div>
</li>
</ul>

  </Card> 


  <div style={{    display: "flex",
  width: "fit-content",
  alignItems: "center",
  color: "white",
  margin: "18px auto 0px",
  background: "#5590c2",
  padding: "0px 14px 0"}}>
<h3>Chat Disable  </h3>
<li className="tg-list-item">
        
        <input className="tgl tgl-skewed" checked={save.checked}  onChange={Set} id="cb3" type="checkbox" />
        <label className="tgl-btn" data-tg-off="OFF" data-tg-on="ON" htmlFor="cb3"></label>
    </li>

</div>
  </Grid> 





</>
   
 

  );  
}  