import React,{ useState } from 'react';
import axios from 'axios';
import './login.css';
import {Redirect} from 'react-router-dom';
import img from '../../../src/images/logo.png';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import DialogTitle from '@material-ui/core/DialogTitle';



export const Login = ({history}) => {

  
const baseurl = process.env.REACT_APP_BASE_URL;

    const [mes, setMsg] = useState([]);
 
   const [val, setval] = useState({
       phone:'',
       password:''
   });

const [open2, setOpen2] = useState(false);

    const handleClose2 = () => {
    setOpen2(false);
  };
    const myChange=(e) => {
    const {name, value}= e.target;
    setval(prev =>({ ...prev,  [name] : value}) );



    }
    
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetch = async (event) => {
    
        event.preventDefault();
        const res = await axios.post(`${baseurl}/OnlineCourseStudentLogin/studentLogin`, null,{
          signal:signal,
        
          params: {
              phone: val.phone,
              password:val.password
            }
        });
        
  
       (res.data.Message==="Login successfull") &&    localStorage.setItem("key", mes.AccessToken);
        setMsg(res.data);
        return function cleanup(){
          abortController.abort()
        }
  
        
      }
     
     
      if (!localStorage.getItem("key") || localStorage.getItem("key") === "undefined") {
        localStorage.setItem("phone", val.phone);
        localStorage.setItem("key", mes.AccessToken);
      
      }else{
return <Redirect to="/" />

      }
     
console.log(mes);



function sucess(){

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
 
    setOpen2(true);
  }   
 
 
 return history.replace('/')
  
}
    return (


<div className="user_options-forms">
        <div className="user_forms-login" style= {{ textAlign: 'center',  padding: '48px 35px'}}>
          <img src={img} style={{width:'100px'}} alt="logo" />
          <h2 className="forms_title" style={{fontWeight:'600', marginBottom: '19px'}}> GharPeShiksha </h2>
          <h2 className="forms_title" style={{fontWeight:'600', fontSize: '16px'  }}>Education SImplified</h2>
          <form className="forms_form"  autoComplete='off' onSubmit={fetch} >
        

            <fieldset className="forms_fieldset" style={{border: "none"}}>
              
              <div className="forms_field">
                <input maxLength="10" value={val.phone} onChange={myChange} type="number"  className="forms_field-input" placeholder="Enter Your Phone Number" name="phone" required />
              </div>
			
			  <div className="forms_field">
                <input onChange={myChange} type="password" className="forms_field-input" placeholder="Enter Password" name="password"  required />
              </div>
              
               
            </fieldset>
                    <div className="forms_buttons" align="center">
              <button type="submit"   className="forms_buttons-action">login</button>
            </div>     
          </form>

			<div className="forms_buttons" align="center">
			
			{
(mes.Message === 'Login successfull') ?   sucess() : <p style={{color:'red'}}>{mes.Message}</p> 
                
            }
			 <p>New to GharPeShiksha, Don't have an Account?  <Link to='/signup'><span  style={{textDecorationLine:'underline'}}> Sign Up here!</span></Link></p>
            </div>
    
      </div>
      <AppPop op={open2} cl={handleClose2}  /> 
      </div>
    
    )


    
}













export  function AppPop(prop) {




  return (
    <div>
   
      <Dialog PaperProps={{style:{padding:'20px 0',margin: '0',width: '100%'}}}
        open={prop.op}
        onClose={prop.cl}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="loginh" id="alert-dialog-title" style={{textAlign:'center'}}>{"For Much Better Experience"}</DialogTitle>
        <p className="loginp"> Download Our GharpEshiksha Learners App</p>
<div style={{margin: '12px 0', textAlign: 'center'}}>
 <Link to="https://play.google.com/store/apps/details?id=com.gharpeshiksha.onlineclassroom"> <img width="250px" src="https://www.gharpeshiksha.com/img/dowmload.png" alt="" /></Link>
</div>
        <DialogActions style={{justifyContent:'center'}}>
        <Button onClick={prop.cl}  color="primary">
            Not Now
          </Button>
       
        </DialogActions>
      </Dialog>

  
    </div>
  );
}
