import axios from 'axios';







export const fetchData2 = async() => {

    const baseurl = process.env.REACT_APP_BASE_URL;

    try{

        const data = await axios.get(`${baseurl}/uploadurl.jsp?url=${baseurl}/addStudentDoubt`);

       return data.data
        
    }


    catch(err){

 console.log(err);
 
    }
}

