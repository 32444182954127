import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {reset} from '../../../actions/actions';


  
export  function Alert(prop) {

  const baseurl = process.env.REACT_APP_BASE_URL;
  let dispatch = useDispatch();
    let phone = localStorage.getItem("phone");
    let tokenn = localStorage.getItem("key");
    const [data, setData] =useState([]);
    const logout= async() => {

      const res = await axios.post(`${baseurl}/OnlineCourseStudentLogin/logoutalldevices`, null,{
            
            
             params: {
                 phone: phone,
                  accessToken: tokenn
               }
           });
     
           (res.data.result ==="Success" )&& dispatch(reset());
           setData(res.data.result);
         
       };
    
       if(data==="Success"){
        localStorage.setItem("phone", "");
        localStorage.setItem("key", "undefined");
        return < Redirect to="/login" /> 
    
      }

  return (
    <div>
   
      <Dialog
        open={prop.op}
        onClose={prop.cl}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"SignOut All Devices "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Your account is Already active on another device. You should Logout from all other
           devices and Login again !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
          <Button onClick={logout} color="primary" autoFocus>
            Logout All Devices
          </Button>
        </DialogActions>
      </Dialog>

  
    </div>
  );
}





export  function Alert2(prop) {
  let dispatch = useDispatch();
  const baseurl = process.env.REACT_APP_BASE_URL;
   
const [data, setData] =useState([]);
    let phone = localStorage.getItem("phone");
    let tokenn = localStorage.getItem("key");


    const logout= async() => {

 const res = await axios.post(`${baseurl}/OnlineCourseStudentLogin/logout`, null,{
       
       
        params: {
            phone: phone,
             accessToken: tokenn
          }
      });

      
      (res.data.result ==="Success" )&& dispatch(reset());
      setData(res.data.result);
      
  };

  
  
  if(data==="Success"){
    localStorage.setItem("phone", "");
    localStorage.setItem("key", "undefined");

    return < Redirect to="/login" /> 

  }

  return (
    <div>
   
      <Dialog
        open={prop.op}
        onClose={prop.cl}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Are you sure you want to logout ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={prop.cl} color="primary" autoFocus>
          Cancel
          </Button>
          <Button onClick={logout} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
