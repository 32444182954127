/*eslint-disable */

import React,{useState, useEffect} from 'react';
import {Typography, Grid, Card, CardMedia, Button } from '@material-ui/core';
import ReactHlsPlayer from '../section/Live';
import SendIcon from '@material-ui/icons/Send';
import  {Reload1} from '../section/socket1';
import '../section/liveclass.css'
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import ForumIcon from '@material-ui/icons/Forum';
import {VideoLive} from './VideoLive';



export const TeacherLiveClass = (prop) =>{

    console.log(prop)

    const{id} = prop.match.params;

  useEffect(() => {


screenShare(id)
    return () => {};
  
  }, []);
 
 
return (
    
  <Grid  item xs={12} item md={12} >
  <div style={{background:'aliceblue', width:'100%', padding: '12px',minHeight: '60em',padding:'0'}} >
  <Grid style={{display:"flex",flexWrap: 'wrap',margin:"0",width:'100%', justifyContent: "space-between", background: "rgb(85 144 194)",padding: '8px 12px',  color: "white"}} item md={12}>

 <h2 style={{margin:'0'}}> {prop.match.params.subject}</h2> 
<h2 style={{margin:'0'}}> {prop.match.params.title}</h2>
</Grid>


<Grid style={{display:"flex",flexWrap: 'wrap'}}>
   <Grid  item xs={12} item md={9} >
    <div style={{background:'aliceblue', width:'100%', padding: '12px',padding:'0'}} >
  

         <div style={{display:"flex",flexWrap: 'wrap'}}> 
   

     
            <Card style={{width:'100%'}}>
         <Card  style={{alignItems: 'center', padding:'0'}}> 

 
         <article style={{minHeight: '50em', position: 'relative'}}>
        <div className="github-stargazers" />
        <section id="logs-message" className="experiment" style={{display: 'none', textAlign: 'center', fontSize: '1.5em', lineHeight: 2, color: 'red'}}>WebRTC getDisplayMedia API.</section>
        {/* // just copy this <section> and next script  */}
        <section className="experiment">
          <section className="hide-after-join" style={{textAlign: 'center'}}>                    
            <input type="text" id="room-name" placeholder="Enter " style={{width: '80%', textAlign: 'center', display: 'none'}} />
            <Button  style={{marginTop: '14em'}} id="share-screen" className="setup" variant="contained" color="primary">Share Your Screen</Button>
          </section>
       {/* // local/remote videos container  */}
          <div id="videos-container" />
          <section id="unique-token" style={{display: 'none', textAlign: 'center', padding: '20px'}} />
        </section>
        <section className="experiment"><small id="send-message" /></section>
      </article>

</Card>
          </Card> 
      


         
          </div>


        </div>
      
        
    </Grid>
    <VideoLive {...prop}/>
    </Grid>

    </div>
     
     </Grid>
 
  )
}





function screenShare(id){
 
  
  let button = document.getElementById("share-screen");

  let config = {
           
      openSocket: function(config) {
          let SIGNALING_SERVER = 'https://socketio-over-nodejs2.herokuapp.com:443/';

          let newId = id;
       
          config.channel = config.channel || newId;
         console.log(config.channel);
        
          let sender = newId;
          console.log(sender);
          io.connect(SIGNALING_SERVER).emit('new-channel', {
              channel: config.channel,
              sender: sender
          });

          let socket = io.connect(SIGNALING_SERVER + config.channel);
          socket.channel = config.channel;
          socket.on('connect', function () {
              if (config.callback) config.callback(socket);
          });

          socket.send = function (message) {
              socket.emit('message', {
                  sender: sender,
                  data: message
              });
          };

          socket.on('message', config.onmessage);
      },
      onRemoteStream: function(media) {
          if(isbroadcaster) return;

          let video = media.video;
          videosContainer.insertBefore(video, videosContainer.firstChild);
          rotateVideo(video);

          document.querySelector('.hide-after-join').style.display = 'none';
      },
      onRoomFound: function(room) {
          if(isbroadcaster) return;

          conferenceUI.joinRoom({
              roomToken: room.roomToken,
              joinUser: room.broadcaster
          });

          document.querySelector('.hide-after-join').innerHTML = '<img src="https://www.webrtc-experiment.com/images/key-press.gif" style="margint-top:10px; width:50%;" />';
      },
      onNewParticipant: function(numberOfParticipants) {
          let text = numberOfParticipants + ' users are viewing your screen!';
          
          if(numberOfParticipants <= 0) {
              text = 'No one is viewing your screen YET.';
          }
          else if(numberOfParticipants == 1) {
              text = 'Only one user is viewing your screen!';
          }

          document.title = text;
          showErrorMessage(document.title, 'green');
      },
      oniceconnectionstatechange: function(state) {
          let text = '';

          if(state == 'closed' || state == 'disconnected') {
              text = 'One of the participants just left.';
              document.title = text;
              showErrorMessage(document.title);
          }

          if(state == 'failed') {
              text = 'Failed to bypass Firewall rules. It seems that target user did not receive your screen. Please ask him reload the page and try again.';
              document.title = text;
              showErrorMessage(document.title);
          }

          if(state == 'connected' || state == 'completed') {
              text = 'A user successfully received your screen.';
              document.title = text;
              showErrorMessage(document.title, 'green');
          }

          if(state == 'new' || state == 'checking') {
              text = 'Someone is trying to join you.';
              document.title = text;
              showErrorMessage(document.title, 'green');
          }
      }
  };

  function showErrorMessage(error, color) {
      let errorMessage = document.querySelector('#logs-message');
      errorMessage.style.color = color || 'red';
      errorMessage.innerHTML = error;
      errorMessage.style.display = 'block';
  }

  function getDisplayMediaError(error) {
      if (location.protocol === 'http:') {
          showErrorMessage('Please test this WebRTC experiment on HTTPS.');
      } else {
          showErrorMessage(error.toString());
      }
  }

  function captureUserMedia(callback) {
      let video = document.createElement('video');
      video.muted = true;
      video.style.width ='100%';
      video.volume = 0;
      try {
          video.setAttributeNode(document.createAttribute('autoplay'));
          video.setAttributeNode(document.createAttribute('playsinline'));
          video.setAttributeNode(document.createAttribute('controls'));
      } catch (e) {
          video.setAttribute('autoplay', true);
          video.setAttribute('playsinline', true);
          video.setAttribute('controls', true);
      }

      if(navigator.getDisplayMedia || navigator.mediaDevices.getDisplayMedia) {
          function onGettingSteam(stream) {
              video.srcObject = stream;
              videosContainer.insertBefore(video, videosContainer.firstChild);

              addStreamStopListener(stream, function() {
                  location.reload();
              });

              config.attachStream = stream;
              callback && callback();
              rotateVideo(video);

              addStreamStopListener(stream, function() {
                  location.reload();
              });

              showPrivateLink();

              document.querySelector('.hide-after-join').style.display = 'none';
          }

          if(navigator.mediaDevices.getDisplayMedia) {
              navigator.mediaDevices.getDisplayMedia({video: true}).then(stream => {
                  onGettingSteam(stream);
              }, getDisplayMediaError).catch(getDisplayMediaError);
          }
          else if(navigator.getDisplayMedia) {
              navigator.getDisplayMedia({video: true}).then(stream => {
                  onGettingSteam(stream);
              }, getDisplayMediaError).catch(getDisplayMediaError);
          }
      }
      else {
          if (DetectRTC.browser.name === 'Chrome') {
              if (DetectRTC.browser.version == 71) {
                  showErrorMessage('Please enable "Experimental WebPlatform" flag via chrome://flags.');
              } else if (DetectRTC.browser.version < 71) {
                  showErrorMessage('Please upgrade your Chrome browser.');
              } else {
                  showErrorMessage('Please make sure that you are not using Chrome on iOS.');
              }
          }

          if (DetectRTC.browser.name === 'Firefox') {
              showErrorMessage('Please upgrade your Firefox browser.');
          }

          if (DetectRTC.browser.name === 'Edge') {
              showErrorMessage('Please upgrade your Edge browser.');
          }

          if (DetectRTC.browser.name === 'Safari') {
              showErrorMessage('Safari does NOT supports getDisplayMedia API yet.');
          }
      }
  }

  /* on page load: get public rooms */
  let conferenceUI = conference(config);

  /* UI specific */
  let videosContainer = document.getElementById("videos-container") || document.body;
console.log(videosContainer)
  button.onclick =function () {
      let roomName = document.getElementById('room-name') || { };
      roomName.disabled = true;
      captureUserMedia(function() {
          conferenceUI.createRoom({
              roomName: (roomName.value || 'Anonymous') + ' shared his screen with you'
          });
      });
      this.disabled = true;
  };

  function rotateVideo(video) {
      video.style[navigator.mozGetUserMedia ? 'transform' : '-webkit-transform'] = 'rotate(0deg)';
      setTimeout(function() {
          video.style[navigator.mozGetUserMedia ? 'transform' : '-webkit-transform'] = 'rotate(360deg)';
      }, 1000);
  }

  function showPrivateLink() {
      let uniqueToken = document.getElementById('unique-token');
      uniqueToken.innerHTML = '<a href="' + location.href + '" target="_blank">Copy & Share This Private Room Link</a>';
      uniqueToken.style.display = 'block';
  }
  //place your code here, the scripts are all loaded


//web********************************cam



function captureUserMedia1(callback) {
let video = document.createElement('video');
video.setAttribute('autoplay', true);
video.style.width ='100%';
video.setAttribute('controls', false);
participants.insertBefore(video, participants.firstChild);

getUserMedia({
    video: video,
    onsuccess: function(stream) {
        config.attachStream = stream;
        callback && callback();

        video.setAttribute('muted', true);
        rotateVideo(video);
    },
    onerror: function() {
        alert('unable to get access to your webcam.');
        callback && callback();
    }
});
}


captureUserMedia1();




  

}





//===================================================================================================


  
    