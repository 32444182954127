/*eslint-disable */

import React,{useState, useEffect,useRef}  from 'react';
import {Typography, Grid, CircularProgress,Button, Select } from '@material-ui/core';
import axios from 'axios';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Badge from '@material-ui/core/Badge';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from "@material-ui/core/styles";
import {Popup, Repost, Editpost} from './popup';
import {useSelector, useDispatch} from 'react-redux';
import {offline} from '../../../actions/actions';
const useStyles = makeStyles((theme) => ({
    badge: {
      right: -10
    },
     hide : {
      opacity: '0.7',
      display: 'block',
      width: '100%',
      height: 'auto',
      transition: '.5s ease',
      backfaceVisibility: 'hidden',
      pointerEvents: 'none',
      position:'relative'
    },
      hideb:{
      transition: '.5s ease',
      opacity: '1',
      position:' absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
   //   -msTransform: 'translate(-50%, -50%)',
      textAlign: 'center',
      pointerEvents: 'auto',
      zIndex: 1
    }
    
  }));


export const Requirement = ({history}) =>{
    const classes = useStyles();

  const [sub,  setsub] = useState([]);
  const [arr,  setarr] = useState([]);
  const baseurl = process.env.REACT_APP_BASE_URL;
 let phone = localStorage.getItem("phone");
 let tokenn = localStorage.getItem("key");
 const [newid,  setid] = useState(null);
 const [open, setOpen] = useState(false);

  const handleClickOpen = (id) => {
    setOpen(true);
    setid(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

   


  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [number, setnumber] = useState();
  const [msg, setmsg] = useState();
  const [select, setselect] = useState();
  const handleClickOpen2 = (id) => {
    setOpen2(true);
    setid(id);
  };
  const [state, setState] = useState({
    'checkeda':false,
    'checkedb': false,
    'checkedc': false,

   
  });

  const dispatch = useDispatch();
  
  const getData= useSelector(state=>state.data);
  const get= useSelector(state=>state.data.requirements);

  const handleClickOpen3 = async (id) => {
    
 
  
    const params = new URLSearchParams()

    params.append('contact', phone)
    params.append('accessToken', tokenn)
    params.append('enq_id', id)
  
    const res = await axios.post(`${baseurl}/OfflineCourseStudentDashboard/editPost`,params);
  //console.log(res.data)
  setselect(res.data.start_time)
   setsub(res.data.mode.toString().split(","));
   setarr([res.data.mode.toString().split(",")]);
    setOpen3(true);
    setid(id);
  
  }


  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };


 
   
    useEffect(()=>{
      setState({
        'checkeda':false,
        'checkedb': false,
        'checkedc': false,
    
       
      });
      sub.map((data)=>{
        if(data==="Home Tuition at Student's Home"){
                     setState((data)=> {
                      return  {...data,checkeda:true}
                     });
              
                  }
                  else if(data==="Home Tuition at Your Home"){
                    setState((data)=> {
                      return  {...data,checkedb:true}
                     });
                  
                } else if(data==="Online Class"){
                  setState((data)=> {
                    return  {...data,checkedc:true}
                   });
                
                }else{
                  setState((data)=> {
                    return  {...data}
                   });
             
                }

      })
 
  
       
      },[sub]);

      
    

// useEffect(()=>{


// },[]);



async function del(){
 
  
    const params = new URLSearchParams()

    params.append('contact', phone)
    params.append('accessToken', tokenn)
    params.append('enq_id', newid)
    
    const res = await axios.post(`${baseurl}/OfflineCourseStudentDashboard/closePost`,params);
   
    dispatch(offline());
handleClose();
  
}


async function del2(){
 
  
  const params = new URLSearchParams()

  params.append('contact', phone)
  params.append('accessToken', tokenn)
  params.append('enq_id', newid)
  console.log(phone,newid,tokenn)
  const res = await axios.post(`${baseurl}/OfflineCourseStudentDashboard/repost`,params);

   
  dispatch(offline());
handleClose2();

}


async function del3(){
 
  
  const params = new URLSearchParams()

  params.append('contact', phone)
  params.append('accessToken', tokenn)
  params.append('enq_id', newid)
  params.append('alt_contact', number)
  params.append('message', msg)
  params.append('start_time', select)
  params.append('checkedValue',arr.toString() )
  //console.log(phone,newid,tokenn,number,msg,select,arr.toString());
 
const res = await axios.post(`${baseurl}/OfflineCourseStudentDashboard/updatePost`,params);

//console.log(res.data)
dispatch(offline());
handleClose3();

}


const ss={
  padding: "5px 0"
}


const inputval= (e)=>{

setState({ ...state, [e.target.name]: e.target.checked });
if(e.target.checked){
  
  setarr([ ...arr,e.target.value]);
 
}else{
  let x = arr.indexOf(e.target.value);
  
  arr.splice(x,1);
}

}

return (

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >

    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
   
    All Your Requirements 
          </Typography>

       

          <Grid container spacing={3} style={{justifyContent: 'center', backgroundColor: '#E4E9FD'}}>
         
         <div style={{width:'100%', background: '#5590c2',  textAlign: 'center', padding: '12px 8px',  marginBottom: '3em',}}>
             <h3 style={{    color: 'white'}}> {get && get.length}  Requirements Posted </h3>
         </div>
         
         
  { (getData.result==="Error") ? <h1>Data Not found</h1> : (!get) ? <CircularProgress style={{position:'fixed', top:'15em'}} /> :  get.map(({date,status,id,subject,cls,MatchedTutor,RespondedTutor}) =>
   <Grid className={(status==="submitted" && status) ? null : classes.hide} key={id}  item md={10} style={{boxShadow:' 0 5px 20px 2px rgba(0, 0, 0, 0.14)',    padding: '0', margin: '0px 0px 25px', background: 'white'}}>
         
         
<div style={{ padding: '18px 16px',borderBottom: "2px solid #9f9f9f"}}>
    <h2>Tutor Requirement for {subject} in {cls}</h2> <b style={{    padding: '4px'}}><small>  Posted on: {date}</small></b> 
</div>

<div style={{    padding: '8px 30px 30px'}}>
<div style={{display: 'flex', justifyContent: 'space-between',flexWrap: 'wrap'}}>
<Button onClick={()=>history.push(`/match-tutors/${id}`)} disabled={MatchedTutor===0} style={{display:'flex',margin:' 15px 0 0', padding:' 0 4px', color: '#5590c2'}}><Badge   classes={{ badge: classes.badge }} color="secondary" badgeContent={MatchedTutor} showZero={(MatchedTutor===0) && true} >  <RadioButtonCheckedIcon style={{margin: '0px 2px 0 0',padding: '0 2px'}} /> Matching Tutors  </Badge></Button>
 <Button onClick={()=>history.push(`/responded-tutors/${id}`)} disabled={RespondedTutor===0} style={{display:'flex',margin:' 15px 0 0', padding:' 0 4px', color: '#5590c2'}}><Badge      classes={{ badge: classes.badge }} color="secondary" badgeContent={RespondedTutor} showZero={(RespondedTutor===0) && true}>  <CheckCircleIcon style={{margin: '0px 2px 0 0',padding: '0 2px'}} /> Responded Tutors  </Badge></Button>  
 </div>
{(status==="submitted" && status) ? null : <Button onClick={(e)=>handleClickOpen2(id)} className={classes.hideb} style={{outline:'none',background: '#126dba', margin: '6px'}} variant="contained" color="primary">
   Repost This Requirement
</Button>}
 <div style={{display: 'flex', justifyContent: 'space-between',flexWrap: 'wrap'}}>
 <Button onClick={(e)=>handleClickOpen3(id)} style={{display:'flex',margin:' 15px 0 0',color: '#5590c2'}}> <EditIcon style={{margin: '0px 2px 0 0',padding: '0 2px'}} /> Edit Post </Button>
 <Button onClick={(e)=>handleClickOpen(id)} style={{display:'flex',margin:' 15px 0 0',color: '#5590c2'}}>  <CancelIcon style={{margin: '0px 2px 0 0',padding: '0 2px'}} /> Close Post   </Button>
 </div>
</div>  
          </Grid>  
          
          )}
              
    
                        
        


          </Grid>
  
       <Popup op={open} cl={handleClose} sub={del}  /> 
       <Repost op={open2} cl={handleClose2} sub={del2}  /> 

       <Editpost op={open3} cl={handleClose3} handleChange={inputval}
        onChange={(e)=>setselect(e.target.value)} text={select}
       checka={state.checkeda}  checkb={state.checkedb} checkc={state.checkedc} 
        change={(e)=>setmsg(e.target.value)}  change1={(e)=>setnumber(e.target.value)}
        sub={del3} 
       /> 
        </div>
    
    </Grid>  )
}


