/*eslint-disable */
import React,{useState, useEffect} from 'react';
import {Typography, Grid, CircularProgress, Card, CardContent, Button  } from '@material-ui/core';
import {Card1} from './box';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Alert} from './popup'
import styles from './box.module.css';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import {Redirect} from 'react-router-dom';
import student from '../../../images/student.png';


export const Sub = ({history}) =>{

  const baseurl = process.env.REACT_APP_BASE_URL;
    const anc={color: 'inherit',
  textDecoration: 'none'}

  let phone = localStorage.getItem("phone");
  let tokenn = localStorage.getItem("key");
  const [getSub, setSub] = useState([]);
  const [getLive, setLive] = useState([]);
  const [getLive1, setLive1] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


   
useEffect(()=>{
 

  const abortController = new AbortController();
  const signal = abortController.signal;
    const fetch = async () => {
   
      const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/getSubjectsList`, null,{
        signal: signal,

        params: {
            phone: phone,
            accessToken: tokenn
          }
      });
    
      console.log(res.data)
      console.log(tokenn)
      if(res.data.AccessTokenMatch==="False" ){
        return  handleClickOpen();
 
             }
       setSub(res.data.SubjectsList);
       
      

       const res1 = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/getLiveClassesSchedule`, null,{
        signal: signal,

        params: {
            phone: phone,
            accessToken: tokenn
          }
      });

      // console.log(res1.data)
    
      setLive(res1.data.UpcomingLiveClasses)
      setLive1(res1.data.LiveNow)
    }
    fetch();

  //  console.log(getLive1);

   if(!localStorage.getItem("phone") || phone===''){
    history.push('/login')
    
      }
  return function cleanup(){
    abortController.abort()
  }




},[])


const reload =(Url, ClassroomId, Subject, Title)=>{


  history.push({pathname:`/live-class`, state:{Url, ClassroomId, Subject, Title}})
  window.location.reload();
}

return (
<>

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '0px',minHeight: '22em'}} >
    <div  style={{background:' #D0E9F2', padding:' 28px 0px'}} >
    {(!getLive1.length) ? null : 
<Typography  style={{padding:  "0px 12px 12px"}} variant="h4" component='h2'   gutterBottom>
  
  Live Now
   </Typography>}
   
   {(!getLive1.length) ? null : getLive1.map(({TutorName,Title, Time, Image, Subject, Url, ClassroomId}, id)=> 


 <Grid align='center' key={id}  style={{display: "inline-block",     padding: '12px' }}  item sm={5}>
 <button style={{marginLeft:'auto', color: 'inherit', textDecoration: 'none'}} onClick={()=>reload(Url, ClassroomId, Subject, Title)} > 
 <Card className={''} style={{    boxShadow: '0 2px 7px 2px rgba(0, 0, 0, 0.14)'}}>
 
 <CardContent style={{display:'flex',alignItems: 'center', flexWrap: "wrap",padding: ' 0px'}}>
   <div style={{padding: '0px',width:'100%'}}>
 <img  src={Image} style={{width:'100%',maxHeight: '12em'}} alt='subject' />
 </div>
 <div style={{width: '100%',    display: 'flex',justifyContent: 'space-between',padding: '6px 10px',  alignItems: 'center'}}>
   <h4 style={{margin:0}}>{Time}</h4>
   <h4 style={{margin:0, color: 'white', background: '#457e9e',padding: '2px 10px'}}> {Subject} </h4>
 </div>
 <div style={{ display: "flex",justifyContent: "space-between",width: "100%",padding: "8px 12px", background: "rgb(69 126 158)",  alignItems: 'center'}}> 
 <Typography  style={{    color: 'white',fontSize: '22px'}} variant="h5"   color="textSecondary" >
 
  {Title} 
 </Typography>
 

<PlayCircleFilledIcon style={{    color: 'white',      fontSize: '38px'   }} />

 </div>
  
 
 </CardContent>
 
 </Card>
 </button>
 </Grid>


)}
 </div>

<div onClick={()=>  window.location.href='https://www.gharpeshiksha.com/queryFormReact/queryform.html'} style={{ textAlign:'center', cursor:'pointer',  background: 'aliceblue'}}> 
<img width="100%" src={student}  alt="" />
</div>


 <div  style={{ padding:' 28px 0px',    background: 'aliceblue'}} >
    {(!getLive.length) ? null : 
  <Typography  style={{padding:  "25px 12px 12px"}} variant="h4" component='h2'   gutterBottom>
  
  Upcoming  Live Classes
    </Typography>}

      {(!getLive.length) ? null :getLive.map(({TutorName,Title, Time, Image, Subject}, id)=> 
      
      
      <Grid align='center' key={id}  style={{display: "inline-block",     padding: '12px' }}  item sm={5}>
     
      <Card className={''} style={{    boxShadow: '0 2px 7px 2px rgba(0, 0, 0, 0.14)'}}>
      
      <CardContent style={{display:'flex',alignItems: 'center', flexWrap: "wrap",padding: ' 0px'}}>
        <div style={{padding: '0px',width:'100%'}}>
      <img  src={Image} style={{width:'100%',maxHeight: '12em'}} alt='subject' />
      </div>
      <div style={{width: '100%',    display: 'flex',justifyContent: 'space-between',padding: '6px 10px',  alignItems: 'center'}}>
        <h4 style={{margin:0}}>{Time}</h4>
        <h4 style={{margin:0, color: 'white', background: 'rgb(252 74 26)',padding: '2px 10px'}}> {Subject} </h4>
      </div>
      <div style={{ display: "flex",justifyContent: "space-between",width: "100%",padding: "8px 12px", background: "rgb(252 74 26)",  alignItems: 'center'}}> 
     
      <Typography  style={{    color: 'white',fontSize: '22px'}} variant="h5"   color="textSecondary" >
      
      {Title} 
     </Typography>
     
      <Typography  style={{    color: 'white',fontSize: '22px'}} variant="h5"   color="textSecondary" >
      
       {TutorName} 
      </Typography>
      
     
   
     
      </div>
       
      
      </CardContent>
      
      </Card>
   
      </Grid>
      )}
      </div>



      {/* <div> 
    <Link style={ {color:'inherit', padding:'8px'}}  to='/'>Subjects </Link> 
  
    </div> */}
    
    <Typography  style={{padding: '12px', background: 'aliceblue'}} variant="h4" component='h2'   gutterBottom>
  
          Subject
          </Typography>
          <div style={{    padding: '0px 14px 55px',  background: 'aliceblue'}}>
          <Grid container spacing={3} style={{justifyContent: 'center'}}>
          
         
          { (!getSub.length) ? <CircularProgress style={{position:'fixed',top:'15em'}} /> :getSub.map((data, id) => {
            return  <Grid align='center' key={id}  item md={3} >   <Link style={anc} to={`/chapter/${data.Subject}`}>
           <Card1  key={id}  image={data.Image} sub={data.Subject} chap={`${data.ChapterCount} chapters`} /> </Link> </Grid>
          }) }
  
       
  
          <Alert op={open} cl={handleClose} />
          </Grid>
          </div>
       
        </div>
      


    </Grid>

    </>
    
    )
}


