import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Typography, Grid} from '@material-ui/core';
import styless from './modal.module.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});




const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);





const mystyle ={
  
  margin:0,
  padding: '0px 0px 0 20px'
}


export  function Modal(prop) {


  
  return (
    <div>
  
      <Dialog  PaperProps={{ style: {margin: '0', width:' calc(100% - 15px)', maxHeight: 'calc(100% - 20px)'}}} fullWidth={true} maxWidth = {'md'}  onClose={prop.clo} aria-labelledby="customized-dialog-title" open={prop.tt}>
        <DialogTitle id="customized-dialog-title" onClose={prop.clo}>
        {prop.bene}
        </DialogTitle>
        <DialogContent className={styless.bg} style={{padding:'0'}} dividers>

        <Grid  item md={12} style={{color:'white'}}>



    
        <div style={{ padding: '15px 14px'}}>
      
        <h3 style={{fontSize: '30px', fontWeight: '500', marginBottom: '16px', textAlign:'center' }}>Experience the Best Quality Education with GharPeShiksha</h3>

        <p style={{ marginBottom: '30px', textAlign:'center', fontSize: '16px' }}> Learn from a wide range of online courses by our highly qualified and experienced tutors </p>
    
<div className={styless.new}>
           <CheckCircleIcon style={{    fontSize: '35px'}} />
          <Typography style={mystyle} variant="h5" gutterBottom>
          Learn from India's Best Teachers 
         </Typography>
</div>


<div className={styless.new}>
           <CheckCircleIcon style={{    fontSize: '35px'}} />
          <Typography style={mystyle} variant="h5" gutterBottom>
          Full Video Course
         </Typography>
</div>



<div className={styless.new}>
           <CheckCircleIcon style={{    fontSize: '35px'}} />
          <Typography style={mystyle} variant="h5" gutterBottom>
         Live Interactive Doubt Classes and Revision Classes
         </Typography>
</div>


<div className={styless.new}>
           <CheckCircleIcon style={{    fontSize: '35px'}} />
          <Typography style={mystyle} variant="h5" gutterBottom>
          Ask Your Doubt from Teacher any Time
         </Typography>
</div>



<div className={styless.new}>
           <CheckCircleIcon style={{    fontSize: '35px'}} />
          <Typography style={mystyle} variant="h5" gutterBottom>
          Get Exam Ready with Notes
         </Typography>
</div>




<div className={styless.new}>
           <CheckCircleIcon style={{    fontSize: '35px'}} />
          <Typography style={mystyle} variant="h5" gutterBottom>
         Attend Test Series
         </Typography>
</div>


          
     </div>


 

          </Grid>
        </DialogContent>
        <DialogActions style={{    display: 'flex', justifyContent: 'center'}}>
         <a style={{textDecoration:'none'}} href="https://www.gharpeshiksha.com/online_course_pricing.jsp" > <Button variant="contained" align="center"  autoFocus onClick={prop.clo} color="primary">
           Upgrade Plan
          </Button></a>
        </DialogActions>
      </Dialog>
    </div>
  );
}
