/*eslint-disable */
import React,{useState, useEffect} from 'react';
import {Typography, Grid, CircularProgress} from '@material-ui/core';
import {ChapterCard} from './box';
import {Link} from 'react-router-dom';
import axios from 'axios';

import {Alert} from './popup'


export const Chapter = ({match}) =>{
    const anc={
        padding:'8px'
    }


    
    const [getChap, setchap] = useState([]);
 const [open, setOpen] = React.useState(false);
 const baseurl = process.env.REACT_APP_BASE_URL;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

     let phone = localStorage.getItem("phone");
  let tokenn = localStorage.getItem("key");
useEffect(()=>{
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetch = async () => {
    
      
      const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/getchapters`, null,{
 signal:signal,
       
        params: {
            phone: phone,
            subject : match.params.id,
            accessToken: tokenn
          }
      });
      
      if(res.data.AccessTokenMatch==="False" ){
        return  handleClickOpen();
 
             }
      setchap(res.data.ChaptersList)
       console.log(res.data.ChaptersList)
        
    }
    fetch();

    
    return function cleanup(){
        abortController.abort()
      }



},[])



return (
 

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >
    <div> 
    <Link style={anc} to='/'> Subjects </Link> 
    
    <Link  style={{color:'inherit', padding:'8px'}} to={`/chapter/${match.params.id}`} >Chapter</Link> 
  
    </div>
    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
          {match.params.id}
          </Typography>
          <Grid container spacing={3} style={{justifyContent: 'center',background: '#a2c0da',  padding: '8px 0'}}>

         
          { getChap.length ?
              getChap.map(({Image, Chapter, ChapterNumber, TopicsCount, ChapterId, FreeVideoContentCount }, id) =>
                <ChapterCard key={id} listv={TopicsCount} inside={(FreeVideoContentCount === 0) ? null :  `${FreeVideoContentCount} Free Content Inside` } image={Image} name={Chapter} chap={ChapterNumber} loc={{pathname :`/video/${match.params.id}/${ChapterId}`, state:{Chapter}}} /> )
              : <CircularProgress style={{position:'fixed', top:'15em'}} /> }
  
  
              <Alert op={open} cl={handleClose} />
  
          </Grid>
  
       
        </div>
      
    </Grid>

    )
}