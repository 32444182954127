/*eslint-disable */

import React,{useState, useEffect}  from 'react';
import {Typography, Grid, TextField,Button, Select } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import {fetchData2} from '../../../api'








export const Doubt = () =>{
  const baseurl = process.env.REACT_APP_BASE_URL;
  const [profile,  sprofile] = useState([]);
 let tokenn = localStorage.getItem("key");
    const anc={color: 'inherit',
  textDecoration: 'none'}


  let phone = localStorage.getItem("phone");

    const [user, setUser] = useState({
      name:'',
      fname:'',
      subname:'',
      desc:'',
      
    });
    const [getclass, setclass] = useState([]);
   const [v, setv] =useState([]);
   const [img1, setimg] = useState({ 
    uploads: ''
  })
  
   
   const handlechangeall = (e) =>{

     const {name, value}= e.target;
     setUser(prev =>({ ...prev,  [name] : value}) );

     
   }



  const [l1, setl1] = useState(0);
  
  const fileChangeHandler = (e) => {
    const {name, files}= e.target;
    setimg(prev =>({ ...prev,  [name] : files[0] }) )
  
    setl1(files.length);
    
  }
    const [res, setres] = useState([]);


 
   
    

useEffect(()=>{
  const abortController = new AbortController();
  const signal = abortController.signal;

  
    const fetch = async () => {
  
      setv(await fetchData2());


      const res0 = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/UserInformation`, null,{
  
        signal:signal,
        params: {
            phone: phone,
            accessToken:tokenn
          }
      });
  

      const res1 = await axios.get(`${baseurl}/OnlineCourseStudentLogin/getCourses`);
      setclass(res1.data.courses);
      sprofile(res0.data)
      



     
    }

   
    fetch();

    return function cleanup(){
      abortController.abort()
    }
  
  
},[]);




console.log(profile.course)
 const submit= async (event) => {

  event.preventDefault();
if(user.name==='' || user.subname===''){
  
  return false

}

  let formdata = new FormData();
  formdata.append("phone", phone);
    formdata.append("title", user.name);
    formdata.append("course",profile.course);
    formdata.append("topic", user.fname);
    formdata.append("subject", user.subname);
    formdata.append("description", user.desc);
    formdata.append("noOfImages",l1);
    formdata.append('photo1',img1.uploads )
   
  const res1 = await axios.post(v, formdata);

  setres(res1.data);


}










const ss={
  padding: "5px 0"
}
  


return (

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >

    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
   
    Ask Your Doubts 
          </Typography>
          <Grid container spacing={3} style={{justifyContent: 'center', backgroundColor: '#E4E9FD'}}>
          <Grid   item md={6} style={{boxShadow:' 0 5px 20px 2px rgba(0, 0, 0, 0.14)',    padding: '29px 20px',marginBottom:'30px', margin: '50px 0px',
    background: 'white'}}>
         
         
         
          <form  style={{textAlign:'center'}}   autoComplete="off" >
      
            <div style={ss}>
            <TextField name="name" value={user.name} onChange={handlechangeall} style={{width:'100%'}} id="standard-basic" label="Title" required/>
            </div>

            <div style={ss}>
            <TextField name="fname" value={user.fname} onChange={handlechangeall} style={{width:'100%'}} id="standard-basic" label="Topic" required/>
            </div>





            {/* <div style={ss}>
            <TextField name="subname" value={user.subname} onChange={handlechangeall} style={{width:'100%'}} id="standard-basic" label="Subject" required/>
            </div> */}


            <div  style={ss}>
             <Select className="forms_field-input" style={{width:"100%"}} name="subname" value={user.subname}  native onChange={handlechangeall} required>
        <option aria-label="None"  value="" > Select Subject</option>
        {getclass.length && getclass.map((value,id) => (
          <option key={id} value={value}>{value}</option>
       
        ))}
         
        </Select>
              </div>

            <div style={ss}>

            <TextField name="desc" value={user.desc} onChange={handlechangeall}  style={{width:'100%'}}
          id="standard-textarea"
          label="Description"
          multiline
          rows={2}
       
        />

            </div>

            <div>
            <Button
  variant="contained"
  component="label"
 
  startIcon={<CloudUploadIcon />}
>
  Upload File
  <input 
  name="uploads"
  onChange={fileChangeHandler} 
    type="file"
    style={{ display: "none" }}
  />
</Button>
            </div>

            <Button  onClick={submit} type="submit" style={{outline:'none',marginTop:'30px'}} variant="contained" color="primary">
      Submit
</Button>
      <p style={{marginTop: '20px'}}>{res.result}</p>      
    </form>
   

  </Grid>   

          
          </Grid>
  
       
        </div>
    
    </Grid>  )
}


