/*eslint-disable */

import React,{useState, useEffect, useRef}  from 'react';
import {Typography, Grid, TextField,MenuItem,Button } from '@material-ui/core';
import ModalImage from "react-modal-image";

import axios from 'axios';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import PersonIcon from '@material-ui/icons/Person';

import img from '../../../images/ChatCornerImage.png';
import img5 from '../../../images/ChatCornerImage1.png';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

console.log(img5);
console.log(img);


export const MyDoubtSeen = ({match,location,history}) =>{
  let phone = localStorage.getItem("phone");
  let QuestionShow = location.state.Question;
  let QuestionDes =  location.state.Description;
  let tokenn = localStorage.getItem("key");
  const baseurl = process.env.REACT_APP_BASE_URL;

  const imgref = useRef(null);


  const anc = {color: 'inherit',  textDecoration: 'none'}
  const [dataa,  setdataa] = useState([]);
  const [profile,  sprofile] = useState([]);
  const [ph,  seph] = useState([]);
  const [newurl,  seturl] = useState([]);
    
  const [newArray, setarr] = useState([]);
 useEffect(()=>{
  const abortController = new AbortController();
    const signal = abortController.signal;

    
  const fetch = async () => {
    
    const res00 = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/loadQuestion`, null,{
  
      signal:signal,
      params: {
          phone: phone,
          questionId: match.params.id,
          accessToken:tokenn
        }
    });
 
    seph(res00.data.TutorAssigned)
 
    console.log(res00.data.TutorAssigned);
 
 
    const res0 = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/UserInformation`, null,{
  
      signal:signal,
      params: {
          phone: phone,
          accessToken:tokenn
        }
    });
 
    sprofile(res0.data);
    
 
 
  const res1 = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/loadChat`, null,{
  
  
    signal:signal,
 
    params: {
        phone:phone,
        studentDoubtId:match.params.id,
        accessToken: tokenn
      }
  });
 
  setdataa(res1.data);


   
 const res22 = await axios.get(`${baseurl}/uploadurl.jsp?url=${baseurl}/addDoubtConversationMultiPart`);


 seturl(res22.data)
 console.log(res22.data);


 
    
    
}
fetch();
return function cleanup(){
  abortController.abort()
}


  
},[]);




const [val, setVal] = useState(''
);
const [iii, ggg] = useState(''
);
const [img1, setimg] = useState({ 
  uploads: ''
})

const [l1, setl1] = useState(0);

const fileChangeHandler = (e) => {
  const {name, files, value}= e.target;
  setimg(prev =>({ ...prev,  [name] : files[0] }) )

  setl1(files.length);
  ggg({[name]: value})
  
}





const sub = async (event)=>{

  event.preventDefault();

  
  if(val === '' && l1 === 0  ){
 alert('please write something !');

    return false
  }


 

  let formdata = new FormData();
  formdata.append("studentNumber", phone);
    formdata.append("studentDoubtId", match.params.id);
    formdata.append("sendBy", 'Student');
    formdata.append("message", val);   
    formdata.append("tutorNumber",ph);
    formdata.append("noOfImages",l1);
    formdata.append('photo1',img1.uploads )
   

    setarr((set) => ([...set, {'name': val, 'img':iii.uploads}]))
    setVal('');
 const res2 = await axios.post(newurl, formdata);

 
 
}


console.log(newArray);

const ss={
  padding: "5px 0"
}
  

 
  function openImg(id){
    alert('sdcsd')
    console.log(id)

  }

return (
 
    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >

    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
   
    My Doubts 
          </Typography>
          <Grid item md={12} style={{padding:'10px 26px'}}>
         
    <Button variant="outlined"  color="primary" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />  }>
  Back 
</Button>
          
          </Grid>
      
          <Grid container spacing={5} style={{justifyContent: 'center'}}>
          <Grid   item md={11} style={{boxShadow:' 1px 5px 15px #ddd',   paddingBottom: '15px',marginBottom:'10px',marginTop:'20px',}}>
             <Grid   item md={12} style={{  padding: '10px',border:'1px solid #ccc', borderRadius:'0px 0px 15px 15px'}}>
				
				<div style={{fontSize:'17px',fontWeight:'500',margin:'0'}}><div style={{display:'flex'}}>
<PersonIcon style={{fontSize:'50px',}}/><div><h4 style={{marginTop:'15px',marginLeft:'10px',marginBottom:'0'}}>{profile.name}</h4><h5 style={{padding:'0',margin:'0',color:'rgb(125 125 125)',marginLeft:'10px'}}>{profile.course}</h5></div></div></div>
		
			   <br/>
<p style={{fontSize:'20px',fontWeight:'500',margin:'0'}}><span>{QuestionShow}</span></p>
<p style={{fontSize:'17px',fontWeight:'400',margin:'0', color:'#808080'}}><span>{QuestionDes}</span></p>
			 </Grid>
			 <br/>
			 

       <Grid   item md={12} style={{  padding: '10px',background:'#ffe4c4' , backgroundImage: 'url("https://www.transparenttextures.com/patterns/dimension.png")',maxHeight:'400px',overflowY:'scroll'}}>
       
     { dataa.map(({date, images, message, sendBy}, id)=>(<div key={id} style={{textAlign: (sendBy ==='Tutor') ? 'left' : 'right' ,padding:'10px 20px',position:'relative'}}>
        <img src={(sendBy ==='Tutor') ? img :null} style={{width:'17px',position:'absolute',marginLeft:'-15px',zIndex:'1'}}></img>
          
          
          <div style={{backgroundColor:'#fff',padding:'7px',borderRadius:'0px 6px 6px 6px ',display:'inline-block',lineBreak:'anywhere',maxWidth:'60%'}}>
        
      {images.map((da, id)=> <div  key={id} style={{ maxWidth: "400px" }}>
          <ModalImage
          hideDownload={true}
          hideZoom={true}
          showRotate={true}
            small={da}
            large={da}
          />
        </div> )}
          <div style={{backgroundColor:'#fff',padding:'7px',borderRadius:'0px 0px 0px 6px ',textAlign:'left'}}>
            {message}
          </div>
          <div style={{backgroundColor:'#fff',padding:'7px',borderRadius:'0px 6px 6px 0px',textAlign:'right',padding:'0'}}>
            <b style={{fontSize:'12px',color:'#808080',marginLeft:'8px',fontWeight:'400',position:'relative',top:'4px'}}>
            {date}
            </b>
          </div>
          <img  src={(sendBy ==='Student') ? img5 : null} style={{width:'17px',position:'absolute',marginLeft:'4px',zIndex:'1',top:'10px'}}></img>
          </div>
        </div>))  }



      
{ newArray.map(({name, img}, id) => (<div key={id} style={{textAlign: 'right' ,padding:'10px 20px',position:'relative'}}>
   
          <div style={{backgroundColor:'#fff',padding:'7px',borderRadius:'0px 6px 6px 6px ',display:'inline-block',lineBreak:'anywhere',maxWidth:'60%'}}>
        
      <p style={{margin:'2px'}}> <img  src={img} style={{width:'100%'}}></img></p>
          <div style={{backgroundColor:'#fff',padding:'7px',borderRadius:'0px 0px 0px 6px ',textAlign:'left'}}>
            {name}
          </div>
          <div style={{backgroundColor:'#fff',padding:'7px',borderRadius:'0px 6px 6px 0px',textAlign:'right',padding:'0'}}>
            <b style={{fontSize:'12px',color:'#808080',marginLeft:'8px',fontWeight:'400',position:'relative',top:'4px'}}>
            {new Date().toLocaleString()}
            </b>
          </div>
          <img src={ img5} style={{width:'17px',position:'absolute',marginLeft:'4px',zIndex:'1',top:'10px'}}></img>
          </div>
        </div>))}


        </Grid>
				

         <br/>
			     <Grid   item md={12} style={{  padding: '10px',backgroundColor:'rgb(170 170 170)',borderRadius: '61px'}}>
				
				 <div style={ss} style={{display:'flex'}}>
             <form  style={{width:'100%',display:'flex'}} autoComplete="off" >
			 <input type="text" onChange={e => setVal(e.target.value)}  placeholder="Ask doubt..."  value={val}  name="msg"  style={{fontSize:'17px',fontFamily:'roboto, sans-serif',letterSpacing:'0.5px',width:'100%',height:'50px',backgroundColor:'#fff',borderRadius:'25px 25px 25px 25px',paddingLeft:'10px',paddingRight:'10px'}}   variant="filled"  autoFocus  required/>
			
         <Button  variant="contained"  component="label"   name="upload"  startIcon={<AttachFileIcon style={{transform:'rotate(-29deg)',marginLeft:'12px'}} />} style={{minWidth:'20px',padding:'5px 10px',borderRadius:'50px',marginLeft:'5px',backgroundColor:'#fff',boxShadow:'none'}}>
			<input     type="file"   onChange={fileChangeHandler}  name="uploads" style={{ display: "none" }}  multiple/></Button> 
       
			<Button  variant="contained" type="submit" onClick={sub} component="label"   startIcon={<SendIcon style={{marginLeft:'12px'}} />} style={{minWidth:'20px',padding:'5px 10px',borderRadius:'50px',marginLeft:'5px',backgroundColor:'#fff',boxShadow:'none'}}>
			</Button>
			   
			   </form>
			   <div>
         
            </div>
			
            </div>

			 </Grid>
 
  </Grid>  
  

          </Grid>
  
       <Grid   item md={12}><br/></Grid>
        </div>
    
    </Grid> )
}


