/*eslint-disable */
import React,{useState, useEffect} from 'react';
import {Typography,Card, Grid, CardMedia, CardContent, Button } from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';  
import {Alert} from './popup';
import AppBar from '@material-ui/core/AppBar';  
import Tabs from '@material-ui/core/Tabs';  
import Tab from '@material-ui/core/Tab';  
import pp from '../../../images/pdf.png';
import Box from '@material-ui/core/Box';  
import coming from '../../../images/coming.jpg';

function TabPanel(props) {  
  const { children, value, index, ...other } = props;  
  
  return (  
    <Typography  
      component="div"  
      role="tabpanel"  
      hidden={value !== index}  
      id={`simple-tabpanel-${index}`}  
      aria-labelledby={`simple-tab-${index}`}  
      {...other}  
    >  
      { <Box p={3}>{children}</Box>}  
    </Typography>  
  );  
}  
  
TabPanel.propTypes = {  
  children: PropTypes.node,  
  index: PropTypes.any.isRequired,  
  value: PropTypes.any.isRequired,  
};  
function testProps(index) {  
  return {  
    id: `simple-tab-${index}`,  
    'aria-controls': `simple-tabpanel-${index}`,  
  };  
}  





export  function Notes({first,  chapId}) {  
  const [value, setValue] = React.useState(0);  
  const baseurl = process.env.REACT_APP_BASE_URL;
  const handleChange = (event, newValue) => {  
    setValue(newValue);  
  };  

  
 const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };


  
    
const [getNote, setNote] = useState([]);


useEffect(()=>{


    const abortController = new AbortController();
    const signal = abortController.signal;
    let phone = localStorage.getItem("phone");
  let tokenn = localStorage.getItem("key");
    const fetch = async () => {
    

      const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/getNotes`, null,{
        signal:signal,
       
        params: {
            phone: phone,
            chapterId : chapId,
             accessToken: tokenn
          }
      });
      

     




      if(res.data.AccessTokenMatch==="False" ){
        return  handleClickOpen2();
 
             }

             setNote(res.data.Notes);
    
     
       console.log(res);
      
      
       
        
    }
    fetch();

    return function cleanup(){
        abortController.abort()
      }



},[])

  

  return (  
    <div>  
      <AppBar style={{background: 'white', color: "#181a1a", alignItems: 'center'}} position="static">  
        <Tabs value={value} onChange={handleChange}>
            
          <Tab label="Content" {...testProps(0)} />  
          <Tab label="Notes" {...testProps(1)} />  
        
        </Tabs>  
      </AppBar>  
      <TabPanel value={value} index={0}>  
       {first}
      </TabPanel>  
      <TabPanel value={value} index={1}>  
   <>
{!getNote.length ? <Grid style={{margin:"0 auto"}} item md={6}> 
      <Card style={{width:'100%'}}>
          <CardMedia  style={{alignItems: 'center', padding:'0'}}> 
         <img style={{width:"100%"}} src={coming} alt='coming' /> </CardMedia> </Card> </Grid> :
  getNote.map(({FileName, Key}, id) => (
    <Grid key={id} align='center' style={{margin: '20px 0px'}} item sm={10}>
      <form target="_blank" action={`https://crm.gharpeshiksha.com/serve?key=${Key}`} method="post">  
    <Card  style={{ boxShadow: '0 2px 7px 2px rgba(0, 0, 0, 0.14)', background:'aliceblue'}}>
     <CardContent style={{display:'flex',alignItems: 'center',     padding: '35px'}}>
     <img  src={pp} style={{width:'10%'}}        alt='pdf' />
     <div style={{margin: '0 12px'}}>
   <Typography  style={{textAlign:'left', fontWeight:'500'}} variant="h5"   color="textSecondary" gutterBottom>
     {FileName}
       </Typography>
 
    
     
       </div>
       
     
         <Button type="submit" style={{outline:'none',marginLeft: 'auto'}} variant="contained" color="primary">
       Preview
 </Button>
   
     </CardContent>
    
   </Card>
   </form>
 
       </Grid>
  ) ) 
 
      }
   </>


   
      </TabPanel>  
      <Alert op={open2} cl={handleClose2} />
    </div>  
  );  
}  