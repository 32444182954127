/* eslint-disable */


import React,{useState, useEffect} from 'react';
import {Typography, Grid, CircularProgress, Button,Card, CardMedia  } from '@material-ui/core';
import {Link} from 'react-router-dom';
import axios from 'axios';
import AvTimerIcon from '@material-ui/icons/AvTimer';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Alert} from './popup'

export const Videopreview = ({match, location, history}) =>{

const anc={
    padding:'8px'
}


const baseurl = process.env.REACT_APP_BASE_URL;
    
const [getVid, setVid] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

let phone = localStorage.getItem("phone");
  let tokenn = localStorage.getItem("key");

 
useEffect(()=>{
  let VdoPlayer= window.VdoPlayer;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetch = async () => {
    
    
      const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/videoPreview`, null,{
        signal:signal,
        
        params: {
          phone : phone,
            videoId :  match.params.id,
               accessToken: tokenn
          }
      });
      
      if(res.data.AccessTokenMatch==="False" ){
        return  handleClickOpen();
 
             }
    
      setVid(res.data)
      
      const ress = await axios.post(`${baseurl}/video/getotp`, null,{
        signal:signal,

        params: {
          phone : phone,
            videoid :  location.state.VideoUrl,
            
          }
      });
     

      
        const video = new VdoPlayer({
          otp:ress.data.otp,
          playbackInfo: btoa(JSON.stringify({
            videoId:location.state.VideoUrl
          })),
          theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
          // the container can be any DOM element on website
          container: document.querySelector( "#embedBox" ),
        });
        
     
          
     

     
    }
    
    fetch();
  
console.log(location);

    return function cleanup(){
        abortController.abort()
      }
  
   
},[])

let check = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    
const mat = location.state.VideoUrl.match(check);

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}
  
const videoID = getId(location.state.VideoUrl);




return (
 

    <Grid  item xs={12} item md={10} >
    <div style={{background:'#e5fbf9', width:'100%', padding: '12px',minHeight: '22em'}} >
    <div style={{display:"flex"}}> 
    {/* <Link style={{ color: 'inherit', textDecoration: 'none'}}  to={`/video/${location.state.store1}/${location.state.store2}`}> */}
    <Button onClick={() => history.goBack()} variant="outlined" color="primary" startIcon={<ArrowBackIcon />  }>
  Back 
</Button>



    </div>
    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
   
          </Typography>
         <Grid container spacing={5} item md={9}  style={{margin:'0 auto', width: '100%'}}>

          { (!getVid.videoTitle) ? <CircularProgress style={{position:'absolute',right: '40em',top:'15em'}} /> : (mat && mat[3]==="youtube.com") ?  <Card style={{width:'100%'}}><CardMedia  style={{alignItems: 'center', padding:'0'}}>
          <iframe width="100%"  height="300px" src={`https://www.youtube.com/embed/${videoID}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
         <div style={{padding:'12px'}}> <Typography   style={{fontWeight:'500'}}  align='left'  variant="subtitle1"  >
          {getVid.videoTitle} <span style={{float:'right'}}> {`Duration: ${getVid.videoDuration}` }</span>
      </Typography> 
      </div>  </CardMedia> </Card> : <><Card style={{width:'100%'}}><CardMedia  style={{alignItems: 'center', padding:'0'}}> <div id="embedBox" style={{width:'1280px',maxWidth:'100%',height:'auto'}}> </div>  <div style={{padding:'12px'}}> <Typography   style={{fontWeight:'500'}}  align='left'  variant="subtitle1"  >
         {getVid.videoTitle}
      <span style={{float:'right'}}> {`Duration: ${getVid.videoDuration}` }</span>
      </Typography> </div>  </CardMedia> </Card> </> }
         
      <Alert op={open} cl={handleClose} />

          </Grid>
         
       
        </div>
      
    </Grid>
  )
}