import React,{ useState, useEffect } from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import {AppPop} from './login'


export const TestUrl = ({match,history}) => {


  const baseurl = process.env.REACT_APP_BASE_URL;

    const [mess, setMsg] = useState([]);
 
    const [open2, setOpen2] = useState(false);

    const handleClose2 = () => {
    setOpen2(false);
  };
    
    useEffect(()=>{
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetch = async () => {
      
        const res = await axios.post(`${baseurl}/OnlineCourseStudentLogin/studentLogin`, null,{
          signal:signal,
       
          params: {
              phone: match.params.phone,
              password:match.params.pass
            }
        });
        
  
       
        setMsg(res.data);
      
       
  
        
      }
      fetch();
   
      return function cleanup(){
        abortController.abort()
      }
      
    
},[]);
localStorage.setItem("key", mess.AccessToken);
localStorage.setItem("phone", match.params.phone);


console.log(mess);

function sucess(){

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
 
    setOpen2(true);
  }   
 
 
 return history.replace('/')
  
}

  return(
       <>
{
      (mess.Message === 'Login successfull') ?  sucess() : (mess.result === 'Error') ? <Redirect to="/login" />  : <CircularProgress style={{marginLeft:' 50%'}} />
  }
<AppPop op={open2} cl={handleClose2}  /> 
   </>
)
   

                
          
    
    


    
}