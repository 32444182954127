import React,{useState} from "react";



export const SelectableAnswer = (props) => {

  const [state, setState] = useState({
    selected: false 
  })



  const handleClick= (value) => {

    console.log(value);
    
    if (!props.disabled) {
     props.onClick(value);
      setState({
        selected: true
      });
    }
  }
 const color = (top, chech, check)=> {
   
    if (check === chech) {
      return chech ? "cg" : "cr";
    } else {
      return "cw";
    }
  }
 
    const { selected } = state;
    const { choice, chech, disabled, topSelected,  check } = props;

    return (
      <div style={{ width: '60%'}}
        className="quiz__options ac"
        onClick={() =>(check=== chech) ? handleClick(true) : handleClick(false) }
      >
        <div
          className={`${
            disabled && !selected ?  color(topSelected, chech, check) : ""
          } ${selected ? (chech===check ? "cg" : "cr") : "cw"}`}
        >
          {choice}
        </div>
      </div>
    );
  }

