import axios from 'axios';



const baseurl = process.env.REACT_APP_BASE_URL;

export const fetchSuccess = (phone,tokenn)=> async (dispatch) => {

    const posts = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/UserInformation`, null,{

           
  
        params: {
          phone: phone,
          accessToken:tokenn
          }
      })

      localStorage.setItem("name", posts.data.name);
        dispatch({
    type: 'getData',
    payload: posts
})

};


export const offline = (phone,tokenn)=> async (dispatch) => {

    const params = new URLSearchParams()

    params.append('contact', phone)
    params.append('accessToken', tokenn)


    const data = await axios.post(`${baseurl}//OfflineCourseStudentDashboard/getTutorData`, params)
       console.log(data.data,'offline')
        dispatch({
    type: 'getOffline',
    payload: data
})

};






export const reset = ()=> {
    return {
        type: 'allReset'
  }


};

