
/*eslint-disable */
import React,{useState, useEffect} from 'react';
import {Typography,Card, CardMedia, Grid, CircularProgress, CardContent } from '@material-ui/core';
import {Link} from 'react-router-dom';
import axios from 'axios';
import HttpsIcon from '@material-ui/icons/Https';
import coming from '../../../images/coming.jpg';
import {Alert} from '../learn/popup';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import {Live} from './live';
import styles from '../learn/box.module.css';

export const Past = ({match, history, location}) =>{

  const baseurl = process.env.REACT_APP_BASE_URL;
 const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

 
const anc={
    padding:'8px'
}


 
    
const [getdata, setdata] = useState([]);


useEffect(()=>{


    const abortController = new AbortController();
    const signal = abortController.signal;
    let phone = localStorage.getItem("phone");
  let tokenn = localStorage.getItem("key");
    const fetch = async () => {
    

      const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/getLiveClassesSchedule`, null,{
        signal:signal,
       
        params: {
            phone: phone,
            
             accessToken: tokenn
          }
      });
      

     




      if(res.data.AccessTokenMatch==="False" ){
        return  handleClickOpen2();
 
             }

             setdata(res.data.RecentlyCompletedClasses);
    
     
       console.log(res);
      
      
       
        
    }
    fetch();

    return function cleanup(){
        abortController.abort()
      }



},[]);





  
return (
  

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >
   
    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
  All Classes
          </Typography>



     <Live
 
     
 first={
<>

<Grid container spacing={3} style={{justifyContent: 'center'}}>

        {!getdata.length ?   <Grid style={{margin:"0 auto"}} item md={6}> <Card style={{width:'100%'}}>
          <CardMedia  style={{alignItems: 'center', padding:'0'}}> 
         <img style={{width:"100%",maxHeight: '12em'}} src={coming} alt='coming' /> </CardMedia> </Card> </Grid> : 
        getdata.map(({TutorName,Title, Time, Image, Subject, Url, Duration},id) =>
        <Grid align='center' key={id}  style={{display: "inline-block",     padding: '12px' }}  item sm={6} md={4}>
        <Link style={{marginLeft:'auto', color: 'inherit', textDecoration: 'none'}}  to={{pathname:`/video`, state:{Url, Title, Duration}}}> 
        <Card className={''} style={{    boxShadow: '0 2px 7px 2px rgba(0, 0, 0, 0.14)'}}>
        
        <CardContent style={{display:'flex',alignItems: 'center', flexWrap: "wrap",padding: ' 0px'}}>
        <div style={{width:'100%'}}>
        <img  src={Image} style={{width:'100%',maxHeight: '12em',    maxWidth: '25em',objectFit:'contain'}} alt='subject' />
        </div>
        <div style={{width: '100%',    display: 'flex',justifyContent: 'space-between',padding: '6px 10px',  alignItems: 'center'}}>
          <h4 style={{margin:0}}>{Time}</h4>
          <h4 style={{margin:0, color: 'white', background: '#457e9e',padding: '2px 10px'}}> {Subject} </h4>
        </div>
        <div style={{ display: "flex",justifyContent: "space-between",width: "100%",padding: "8px 12px", background: "rgb(69 126 158)",  alignItems: 'center'}}> 
        <Typography  style={{    color: 'white',fontSize: '22px'}} variant="h5"   color="textSecondary" >
        
         {Title} 
        </Typography>
        
      
       <PlayCircleFilledIcon style={{    color: 'white',      fontSize: '38px'   }} />
       
        </div>
         
        
        </CardContent>
        
        </Card>
        </Link>
        </Grid>) }
          </Grid>
  
</>



     }


          />





          
       
          

<Alert op={open2} cl={handleClose2} />
        </div>
      
    </Grid>)
}
