/*eslint-disable */
import React,{useState, useEffect} from 'react';
import {Typography,Card, Grid, CardMedia, CardContent, Button } from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';  
import {Alert} from '../learn/popup';
import AppBar from '@material-ui/core/AppBar';  
import Tabs from '@material-ui/core/Tabs';  
import Tab from '@material-ui/core/Tab';  
import {Link} from 'react-router-dom';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Box from '@material-ui/core/Box';  
import coming from '../../../images/coming.jpg';
import {Upcoming} from './upcoming';
import { makeStyles } from "@material-ui/core/styles";

function TabPanel(props) {  
  const { children, value, index, ...other } = props;  
  
  return (  
    <Typography  
      component="div"  
      role="tabpanel"  
      hidden={value !== index}  
      id={`simple-tabpanel-${index}`}  
      aria-labelledby={`simple-tab-${index}`}  
      {...other}  
    >  
      {value === index && <Box p={3}>{children}</Box>}  
    </Typography>  
  );  
}  
  
TabPanel.propTypes = {  
  children: PropTypes.node,  
  index: PropTypes.any.isRequired,  
  value: PropTypes.any.isRequired,  
};  
function testProps(index) {  
  return {  
    id: `simple-tab-${index}`,  
    'aria-controls': `simple-tabpanel-${index}`,  
  };  
}  



const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTabs-fixed": {
      overflowY: "hidden!important",
      overflowX: "auto!important"
    }
  }
}));

export  function Live({first,  chapId, third}) {  

  const baseurl = process.env.REACT_APP_BASE_URL;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);  
  
  const handleChange = (event, newValue) => {  
    setValue(newValue);  
  };  

  
 const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };


  
    
const [getdata, setdata] = useState([]);




useEffect(()=>{


    const abortController = new AbortController();
    const signal = abortController.signal;
    let phone = localStorage.getItem("phone");
  let tokenn = localStorage.getItem("key");
    const fetch = async () => {
    

      const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/getLiveClassesSchedule`, null,{
        signal:signal,
       
        params: {
            phone: phone,
            chapterId : chapId,
             accessToken: tokenn
          }
      });
      

     




      if(res.data.AccessTokenMatch==="False" ){
        return  handleClickOpen2();
 
             }

             setdata(res.data.LiveNow);
    
     
       console.log(res);
      
      
       
        
    }
    fetch();

    return function cleanup(){
        abortController.abort()
      }



},[])

  

  return (  
    <div>  
      <AppBar style={{background: 'white', color: "#181a1a", alignItems: 'center'}} position="static">  
        <Tabs className={classes.root} value={value} onChange={handleChange} >
            
          <Tab label="Past Classes" {...testProps(0)} />  
          <Tab label="Live Classes" {...testProps(1)} />  
          <Tab label="UpComing Classes" {...testProps(2)} />  
        
        </Tabs>  
      </AppBar>  
      <TabPanel value={value} index={0}>  
       {first}
      </TabPanel>  

      <TabPanel value={value} index={1}>  
   <>
   {!getdata.length ?   <Grid style={{margin:"0 auto"}} item md={6}> <Card style={{width:'100%'}}>
          <CardMedia  style={{alignItems: 'center', padding:'0'}}> 
         <img style={{width:"100%"}} src={coming} alt='coming' /> </CardMedia> </Card> </Grid> : 
        getdata.map(({TutorName,Title, Time, Image, Subject, Url},id) =>
        <Grid align='center' key={id}  style={{display: "inline-block",     padding: '12px' }}   item sm={6} md={4}>
        <Link style={{marginLeft:'auto', color: 'inherit', textDecoration: 'none'}}  to={{pathname:`/live-class`, state:{Url}}}> 
        <Card className={''} style={{    boxShadow: '0 2px 7px 2px rgba(0, 0, 0, 0.14)'}}>
        
        <CardContent style={{display:'flex',alignItems: 'center', flexWrap: "wrap",padding: ' 0px'}}>
        <div style={{width:'100%'}}>
        <img  src={Image} style={{width:'100%',maxHeight: '12em',    maxWidth: '25em',objectFit:'contain'}} alt='subject' />
        </div>
        <div style={{width: '100%',    display: 'flex',justifyContent: 'space-between',padding: '6px 10px',  alignItems: 'center'}}>
          <h4 style={{margin:0}}>{Time}</h4>
          <h4 style={{margin:0, color: 'white', background: '#457e9e',padding: '2px 10px'}}> {Subject} </h4>
        </div>
        <div style={{ display: "flex",justifyContent: "space-between",width: "100%",padding: "8px 12px", background: "rgb(69 126 158)",  alignItems: 'center'}}> 
        <Typography  style={{    color: 'white',fontSize: '22px'}} variant="h5"   color="textSecondary" >
        
         {Title} 
        </Typography>
        
      
       <PlayCircleFilledIcon style={{ color: 'white', fontSize: '38px'   }} />
       
        </div>
         
        
        </CardContent>
        
        </Card>
        </Link>
        </Grid>) }
   </>


   
      </TabPanel>  

      <TabPanel value={value} index={2}>  
      <Upcoming />
      </TabPanel>  
     
      <Alert op={open2} cl={handleClose2} />
    </div>  
  );  
}  