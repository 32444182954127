
/* eslint-disable */
import React, {useState} from 'react';
import img from '../images/logo.png';
import styles from './nav.module.css';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {Alert2} from './section/learn/popup'
import {Link} from 'react-router-dom';
import { Divider,  ListItem } from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {ListItemIcon,ListItemText, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  anc:{color: 'inherit',
  textDecoration: 'none',width:"100%"}
  
}));
var date = new Date().getFullYear();

function ElevationScroll(props) {



 
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};







export const Nav = (props) => {
  const anc={color: 'inherit',
  textDecoration: 'none',width:"100%"}
  const getkey= useSelector(state=>state.data.recent_enqId);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [auth, setAuth] = React.useState(true);
  const [open1, setOpen] = useState(false);

  const handleClickOpen1 = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);
const handleChange = (event) => {
  setAuth(event.target.checked);
};

const handleMenu = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};


  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar style={{background:'white', color:'#006aa5'}}>
          <Toolbar>
          <img src={img} width={40} alt='profile' />
            <Typography variant="h6">GharPeShiksha</Typography>
       
            { auth && (
            <div className={styles.sidebar1} style={{marginLeft:'auto'}} >
              <IconButton
               style={{padding:'0'}}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle style={{width:'2em', height:'2em'}} />
              </IconButton>
              <Menu style={{top: '60px'}}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
           
           <Accordion expanded={expanded === 'panel1'} onChange={handleChange1('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
      <ListItem style={{padding:0}}>
        
            <ListItemText primary="Online Video Classes" />
          </ListItem>
      
        
        </AccordionSummary>
        <Divider />
        <AccordionDetails style={{padding:0,flexDirection: 'column'}}>
        <MenuItem  onClick={handleClose}><Link style={anc}  to='/'>Learn</Link></MenuItem>
                <MenuItem onClick={handleClose}> <Link style={anc} to='/ask-your-doubt'>Ask Your Doubts </Link></MenuItem>
                <MenuItem onClick={handleClose}> <Link style={anc} to='/my-doubts'>My Doubts </Link></MenuItem>
                <MenuItem onClick={handleClose}><Link style={anc}  to='/all-live-class'>Live Interactive Class</Link></MenuItem>

  
      </AccordionDetails>
      </Accordion>



      <Accordion expanded={expanded === 'panel2'} onChange={handleChange1('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
         <ListItem  style={{padding:0}}>

            <ListItemText primary="One to One Class" />
          </ListItem>
        
        </AccordionSummary>
         <Divider />
        <AccordionDetails style={{padding:0,flexDirection: 'column'}}>

        <MenuItem onClick={handleClose}> <Link style={anc} to='/my-requirement'> My Requirements </Link></MenuItem>
                <MenuItem onClick={handleClose}> <Link className={classes.anc} style={{ pointerEvents: (!getkey) && 'none'}}  to={{pathname: `/match-tutors/${getkey}`}} >Matching Tutors </Link></MenuItem>
                <MenuItem onClick={handleClose}><Link className={classes.anc} style={{ pointerEvents: (!getkey) && 'none'}} to={`/responded-tutors/${getkey}`}>Responded Tutors</Link></MenuItem>


        </AccordionDetails>
      </Accordion>
     

              
             
                <MenuItem onClick={handleClose}><Link style={anc}  to='/account'>Profile</Link></MenuItem>
                <MenuItem onClick={handleClose}><Link style={anc}  to='/about-us'>About Us</Link></MenuItem>
             
             
                <MenuItem onClick={handleClickOpen1}>Logout  </MenuItem>
               <Alert2 op={open1} cl={handleClose1} /> 
             
              </Menu>
              
            </div>
          )}   
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
     
    </React.Fragment>
  );
}






export const Nav1 = (props) => {
  const anc={color: 'inherit',
  textDecoration: 'none',width:"100%"}

  const classes = useStyles();
 
  const [auth, setAuth] = React.useState(true);
  const [open1, setOpen] = useState(false);

  const handleClickOpen1 = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);
const handleChange = (event) => {
  setAuth(event.target.checked);
};

const handleMenu = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};


  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar style={{background:'white', color:'#006aa5'}}>
          <Toolbar>
          <img src={img} width={40} alt='profile' />
            <Typography variant="h6">GharpeShiksha</Typography>
       
            { auth && (
            <div className={styles.sidebar1} style={{marginLeft:'auto'}} >
              <IconButton
               style={{padding:'0'}}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle style={{width:'2em', height:'2em'}} />
              </IconButton>
           
              
            </div>
          )}   
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
     
    </React.Fragment>
  );
}


