/*eslint-disable */
import React from 'react';
import {Typography, Grid, Card, CardMedia  } from '@material-ui/core';

import img1 from  '../../images/about.jpg';
export const About = () =>{

const anc={
    padding:'8px'
}





return (
 

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >
    {/* <div style={{display:"flex"}}> 
    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
   
   About Us 
         </Typography>

    </div> */}
    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
   
          </Typography>
          <Grid style={{margin:"0 auto"}} item md={12}>
          
          <div style={{display:'flex'}}>
            <img style={{width: '90%', margin: '0 auto'}} src={img1} width="100%"    alt='about'/> 
          </div>
          <div>


            <h3 style={{fontSize: '18px',    padding: '25px 0', fontWeight: '500', fontFamily: 'none'}}> &nbsp;   &nbsp;   &nbsp; Gharpeshiksha.com is a leading edtech company based in new delhi. we are your one-stop destination for finding the right tutor for your child. gharpeshiksha wworks as a platform among students, who are seeking a tutor, and teachers, who are looking for a teaching job. ouy vision is to make education easily accessible and available to students acyoss the country and pyovide opportunities to the experienced and qualified teachers seeking opportunities +o restart their teaching careers. we keep enhancing ouselves in every field and stive to give a better experience to ouy members every time. amid the pandemic, we expanded ouy services to provide a seamless online education experience. just register yourself, choose your dream course and start learning.</h3>
          </div>
          </Grid> 
         
       
        </div>
      
    </Grid>
  )
}