import React from 'react';
import List from '@material-ui/core/List';
import {Typography, Grid } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import styles from '../section/section.module.css';

import {Link} from 'react-router-dom';



export const Side  = () => {


    const sti={
        position: 'sticky',
        top: '75px',
        background:'white'
      }
      const anc={color: 'inherit',
      textDecoration: 'none'}

let name = localStorage.getItem("tname");
let timg = localStorage.getItem("timg");
     

return (

    <Grid  className={styles.sidebar}   item md={2}  style={{padding:' 12px 0'}}>
    <div  style={sti}>
    {/* {(profile.address) ? */}
      <Grid    style={{background: 'rgb(85 144 194)', textAlign:'center'}}>
  
  <img src={timg} width={100} style={{margin:' 20px 0 0px',border: '2px solid white',borderRadius:' 70px',  background: '#ececec'}} alt='profile'/>  
  
   <div style={{padding: '20px 0'}}>
  <Typography style={{color:'white'}} variant="h5" component="h2">
  Hello, {name}
  </Typography>
  {/* <Typography color="secondary" variant="subtitle1" component="h2">
  {profile.course} <span >{profile.board}</span>
  </Typography> */}
</div>
  </Grid> 
  {/* : 'loading....' } */}
   <Divider />
  <List component="nav" aria-label="main mailbox folders">
         <Link style={anc} to='/teacher'>
          <ListItem button>
            <ListItemIcon>
              <LocalLibraryIcon />
            </ListItemIcon>
            <ListItemText primary="Live Classes" />
          </ListItem></Link> 
  
  
     
  
         
        </List>
        <Divider />
       
        </div>
    </Grid>
  
  
)


}