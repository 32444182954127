/*eslint-disable */

import React,{useState, useEffect} from 'react';
import {Typography, Grid, Card, CardMedia, Button } from '@material-ui/core';
import ReactHlsPlayer from './Live';
import {StudentiveClass} from './Live';
import SendIcon from '@material-ui/icons/Send';
import  {Reload} from './socket';
import './liveclass.css';
import axios from 'axios';
import $ from 'jquery'; 

export const LiveClass1 = (prop) =>{

  //console.log(prop);
   const {location} = prop;

  const baseurl = process.env.REACT_APP_BASE_URL;

  let name = localStorage.getItem("name");

let phone = localStorage.getItem("phone");
let tokenn = localStorage.getItem("key");

const [data, setdata]=useState([]);


function Dis (){

  $('.inputMessage').prop('disabled', true);

  $('.inputMessage').css('cursor', 'not-allowed');
  $('.fade').hover(function() {
    $(this).css("cursor", "not-allowed");
  });

 
  $('.fade').css('filter','blur(2px)');
  $('.inputMessage').css('filter','blur(2px)')
}


function Ena (){

  $('.inputMessage').css('cursor', 'text');
    $('.inputMessage').prop('disabled', false);
    $('.fade').hover(function() {
      $(this).css("cursor", "pointer");
    });
    $('.fade').css('filter','blur(0px)');
    $('.inputMessage').css('filter','blur(0px)');
}



useEffect(()=>{

  const abortController = new AbortController();
  const signal = abortController.signal;
    const fetch = async () => {
  


      const params = new URLSearchParams()
      params.append('roomid', location.state.ClassroomId)
     
     
      const res = await axios.post('https://live.gharpeshiksha.com/getChat', params);
             setdata(res.data)
    //console.log(res.data);
   // console.log(location.state.ClassroomId);

             const res1 = await axios.post(`${baseurl}/OnlineCourseTutorDashboard/getPublicChatStatus`, null,{
              signal:signal,
                    
                     params: {
                      classroomId: location.state.ClassroomId
                         
                       }
                   });

                  // console.log(res1.data)
         //  console.log(res1.data.PublicChatStatus,"____status");
                   if(res1.data.PublicChatStatus==='enable'){
                  
                    Ena();
                  }else{
                  
                    Dis();
                  }

    }
    fetch();

    Reload();
   
   
  return function cleanup(){
    abortController.abort()
  }

 
},[]);



  



let COLORS = [
  '#e21400', '#91580f', '#f8a700', '#f78b00',
  '#58dc00', '#287b00', '#a8f07a', '#4ae8c4',
  '#3b88eb', '#3824aa', '#a700ff', '#d300e7'
];
const getUsernameColor = (username) => {
  // Compute hash code
  let hash = 7;
  for (let i = 0; i < username.length; i++) {
     hash = username.charCodeAt(i) + (hash << 5) - hash;
  }
  // Calculate color
  let index = Math.abs(hash % COLORS.length);
  return COLORS[index];
}

console.warn(location.state.Url)


return (
 

   
    <Grid  item xs={12} item md={12} >
    <div style={{background:'aliceblue', width:'100%', padding: '12px',minHeight: '33em',padding:'0'}} >
  

         <div style={{display:"flex",flexWrap: 'wrap'}}> 
         <Grid style={{display:"flex",flexWrap: 'wrap',margin:"0",width:'100%', justifyContent: "space-between", background: "rgb(85 144 194)",padding: '8px 12px',  color: "white"}} item md={12}>

     <h2 style={{margin:'0'}}> {location.state.Subject}</h2>
     <h2 style={{margin:'0'}}> {location.state.Title}</h2>
 </Grid>

         <Grid style={{margin:"0",width:'100%'}} item md={9}>
            <Card style={{width:'100%',background: '#e4e4e4'}}>
         <Card  style={{alignItems: 'center', padding:'0'}}> 
    
    
         {/* <ReactHlsPlayer autoPlay 
    url={location.state.Url}
    // url=''
    // autoplay={true}
    controls={true}
    width="100%"
    height="auto"

/> */}



<StudentiveClass {...prop}/>


</Card>
          </Card> 
          </Grid> 


          <Grid style={{margin:"0",width:'100%'}} item md={3}> 
          <div style={{minHeight: '13em',    background: '#d0d0d0'}}> 
  <table className="visible">
          <tbody><tr>
              <td style={{textAlign: 'right'}}>
                {/* <input type="text" id="conference-name" placeholder="Broadcast Name" /> */}
              </td>
              <td>
                {/* <button id="start-conferencing">New Broadcast</button> */}
              </td>
            </tr>
          </tbody></table>
        <table id="rooms-list" className="visible" />
        <table className="visible">
          <tbody><tr>
              <td style={{textAlign: 'center'}}>
                <h2>
                </h2>
              </td>
            </tr>
          </tbody></table>
          
        <div id="participants" ></div>
        </div>
          <Card style={{width:'100%', minHeight: '37em',position:'relative'}}>
          {/* <div className="chatArea">
        <ul className="messages" style={{margin: '0', padding: '16px'}}>
          dfnfdn
        </ul>
      </div>
         <div  style={{display:'flex', position: 'absolute',bottom: '0', width: '100%',    borderTop: '1px solid #bbbbbb'}}>
          
			 <input type="text"  placeholder="Ask doubt..."    name="msg"  style={{fontSize:'17px',fontFamily:'roboto, sans-serif',letterSpacing:'0.5px',width:'100%',height:'50px',backgroundColor:'#fff',borderRadius:'25px 25px 25px 25px',paddingLeft:'10px',paddingRight:'10px'}}   variant="filled"  autoFocus  required/>
			
      
			<Button  variant="contained" type="submit"  component="label"   startIcon={<SendIcon style={{marginLeft:'12px'}} />} style={{minWidth:'20px',padding:'5px 10px',borderRadius:'50px',marginLeft:'5px',backgroundColor:'#fff',boxShadow:'none'}}>
			</Button>
			   
			  
		
			
            </div> */}


 <ul className="pages">
    <li className="chat page">
      <div className="chatArea">
        <ul className="messages">
        {(!data.length) ? null : data.map(({name,message},id)=>
        <li key={id} className="message" >
          <span className="username" style={{color:getUsernameColor(name)}}>{name}</span>
          <span className="messageBody">{message}</span></li>
        )}
        </ul>
      </div>

<div >
<Button className="sendmsg fade"  variant="contained" type="button"  component="label"   startIcon={<SendIcon style={{marginLeft:'12px'}} />} style={{minWidth:'20%',padding:'17px 12px',boxShadow:'none',position:'absolute', bottom:'0', right:'0'}}>
     
</Button>
      <input className="inputMessage" placeholder="Type here..."/>
     
    
</div>
    </li>
    <li className="login page">
      <div className="form">
        <h3 className="title">What's your nickname?</h3>
        
        <>
        
       <input className="usernameInput" type="text" maxLength="14" defaultValue={name} />
       <input hidden className="phoneInput" type="text" maxLength="14" defaultValue={phone} />
       <input hidden className="roomInput" type="text" maxLength="14" defaultValue={location.state.ClassroomId} />
       
       </>
       
      
      </div>
    </li>
  </ul>

          </Card> 
          </Grid> 

          </div>


        </div>
      
    </Grid>
 
  )
}