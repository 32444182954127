
/*eslint-disable */
import React,{useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TableRow,Typography,Button, Card} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {useSelector, useDispatch} from 'react-redux';
import {fetchSuccess} from '../../../actions/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.54)" // (default alpha is 0.38)
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.87)" // (default alpha is 0.38)
    }

    },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '45ch',
  },
}));


export const Account = () => {
    const classes = useStyles();
    const baseurl = process.env.REACT_APP_BASE_URL;
    const myform = useRef(null);

 
    const [getclass, setclass] = useState([]);
    const [msg, setmsg] = useState();
       const [open, setOpen] = useState(false);
 const getData= useSelector(state=>state.userData);
 

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

const [dist, setdis]=useState(true);

  
 
    let phone = localStorage.getItem("phone");
    let tokenn = localStorage.getItem("key");
   
          useEffect(()=>{
           

            const abortController = new AbortController();
            const signal = abortController.signal;

       
              const fetch = async () => {
               
           
               
                
              const res1 = await axios.get(`${baseurl}/OnlineCourseStudentLogin/getCourses`);
                
              if(getData.AccessTokenMatch==="False" ){
                return  handleClickOpen();
         
                     }

                 setclass(res1.data.courses);
                
               
              
              }
              fetch();
          
              return function cleanup(){
                abortController.abort()
           
              }
          
          
          },[]);
   
          useEffect(()=>{

            const timer = setTimeout(() => {
              setmsg('');
            }, 4000);
          

            return function cleanup(){
              
              clearTimeout(timer);
            }

          },[msg])

const setD=()=>setdis(false);

  
  


const fetchData = async (event) => {

  event.preventDefault();
  
  let form = myform.current;
  let name= form.name.value;
  let email= form.email.value;
  let course= form.course.value;
  let address= form.address.value;

  form.reportValidity();
  if( name ==='' || email==='' || course===''  || address===''){
    return false
  }else{

  setdis(true);
  
const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/updateProfile`, null,{
 

  params: {
    phone: phone,
    name:name,
    location:address,
    email:email,
    course:course


    }
});

setmsg(res.data.Message);
 dispatch(fetchSuccess(phone,tokenn))

}
}

console.log(getData.course);
return (

  <>


    <Grid  item xs={12} item md={10} >
  
    <div style={{background:'white', width:'100%',minHeight: '43em'}} >
      <div style={{padding: '70px 0', background: '#9a9a9a'}}>

      </div>
    {  (!getData.name) ? 'loading....' : 
    <Grid  container justify='space-evenly'>
    <Grid align='center'  item md={12} style={{    marginTop: '-5em'}}>
      <div >
<img src={getData.image} style={{border: '2px solid #c7c7c7', borderRadius: '100px',maxWidth: '140px'}}  alt='profile'/>

<div style={{textTransform: 'uppercase',padding: '8px 0', color: '#3a3a3a'}}>
<Typography style={{fontWeight: '700!important'}} variant="h4"    >
   
{getData.name}
          </Typography>
          <Typography style={{fontWeight: '700!important'}} variant="h4"   gutterBottom>
   
        {  getData.course }, {getData.board}
          </Typography>
          </div>
          </div>
<div style={{teaxtAlign:'center'}}>
      {  (dist===true) ?  <Button onClick={setD} style={{outline:'none',marginLeft: 'auto'}} variant="contained" color="primary">
      Edit
</Button>: null}
</div>
    </Grid>
   <Grid  item md={5}  >



   <form  ref={myform} style={{textAlign:'center',marginBottom: '40px',boxShadow:' 0 10px 6px -6px #777',    background: 'white',padding: '20px 10px'}} className={classes.root} noValidate autoComplete="off" onSubmit={fetchData}>
      <TextField  required disabled={dist} name="name"  style={{width:"100%",margin: '10px 0'}} id="standard-basic" defaultValue={getData.name} label="Name" />
      <TextField  required disabled={dist} name="email"   style={{width:"100%",margin: '10px 0'}} id="standard-basic" defaultValue={getData.email} label="Email" />
  
    <div style={{margin: '10px 0'}}>
      <InputLabel style={{textAlign:'left', fontSize: '12px'}} required htmlFor="age-native-simple">Class</InputLabel>
        <Select
        disabled={dist}
        style={{width:'100%'}}
          native
      
          
          inputProps={{
            name: 'course',
            id: 'age-native-simple',
          }}
        >
          {/* <option aria-label="None" value="" /> */}
          <option  value={getData.course}>{getData.course}</option>
{(getclass.length) && getclass.map((data,id)=>  <option key={id} value={data}>{data}</option>)}
         
          
        </Select></div>

      {/* <TextField required  disabled={dist} style={{width:"100%",margin: '10px 0'}} id="standard-basic" value={profile.course} label="Class" /> */}
      <TextField disabled={dist} name="address"   style={{width:"100%",margin: '10px 0'}} required id="standard-basic" defaultValue={getData.address} label="Address" />
      <TextField disabled={dist} style={{width:"100%",margin: '10px 0 30px'}} id="standard-basic" value={phone} label="Phone" />
      
      {  (dist===false) ?<Button type="submit" style={{outline:'none',marginLeft: 'auto'}} variant="contained" color="primary">
    Update
</Button> : null}
<p style={{color:'red'}}>{msg}</p>
    </form>
    </Grid>
    
    </Grid>}
        </div>
      
    </Grid> 
  </>
)
}