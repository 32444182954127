/*eslint-disable */

import React,{useState, useEffect} from 'react';
import {Typography, Grid, Card, CardMedia, Button } from '@material-ui/core';
import ReactHlsPlayer from '../section/Live';
import SendIcon from '@material-ui/icons/Send';
import  {Reload1} from '../section/socket1';
import '../section/liveclass.css'
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import ForumIcon from '@material-ui/icons/Forum';
import {TLive} from './video';



export const Teacher = (prop) =>{

    const {location, match} = prop;
//console.log(match);


return (
  <Grid  item xs={12} item md={10} >
  <div style={{background:'aliceblue', width:'100%', padding: '12px',minHeight: '33em',padding:'0'}} >
  <Grid style={{display:"flex",flexWrap: 'wrap',margin:"0",width:'100%', justifyContent: "space-between", background: "rgb(85 144 194)",padding: '8px 12px',  color: "white"}} item md={12}>

<h2 style={{margin:'0'}}> {match.params.subject}</h2>
<h2 style={{margin:'0'}}> {match.params.title}</h2>
</Grid>

<TLive
{...prop}
first={
   <>
   <Grid  item xs={12} item md={12} >
    <div style={{background:'aliceblue', width:'100%', padding: '12px',minHeight: '33em',padding:'0'}} >
  

         <div style={{display:"flex",flexWrap: 'wrap'}}> 
   

     
            <Card style={{width:'100%'}}>
         <Card  style={{alignItems: 'center', padding:'0'}}> 

         <ReactHlsPlayer autoPlay
    url={location.state.Url}
    // url=''
    autoplay={true}
    controls={true}
    width="100%"
    height="auto"

/>
</Card>
          </Card> 
      


         
          </div>


        </div>
      
    </Grid>
   </>
}



/>
    </div>
     
     </Grid>
 
  )
}


