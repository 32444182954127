/*eslint-disable */

import React,{useState, useEffect}  from 'react';
import {Typography, Grid, CircularProgress,Button, Select } from '@material-ui/core';
import axios from 'axios';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BookIcon from '@material-ui/icons/Book';
import CallIcon from '@material-ui/icons/Call';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';



export const Profile = ({match,history}) =>{

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [data,  setdata] = useState([]);
let phone = localStorage.getItem("phone");
 let tokenn = localStorage.getItem("key");

 const baseurl = process.env.REACT_APP_BASE_URL;
   
 
   
    

useEffect(()=>{
   const abortController = new AbortController();
  const signal = abortController.signal;

  
    const fetch = async () => {
  
      const params = new URLSearchParams()

      params.append('contact', phone)
      params.append('accessToken', tokenn)
      params.append('tutorid', match.params.id)
      params.append('enq_id', match.params.enq)
      console.log(phone);
      const res = await axios.post(`${baseurl}/OfflineCourseStudentDashboard/viewProfile`,params,signal);
      setdata(res.data.data);
    
    
      //console.log(res.data);

     
    }

   
    fetch();

    return function cleanup(){
      abortController.abort()
    }
  
  
  
  
  
},[]);









return (

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >

    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
   
   Teacher Profile
          </Typography>

       

          <Grid container spacing={3} style={{justifyContent: 'center', backgroundColor: '#E4E9FD'}}>
         
       
         
 
         {(!data.length) ? <CircularProgress style={{position:'fixed', top:'15em'}} /> : data.map(({Contact_First,Gender,Lastactivedate,Name,Contact,Contact_Last,Area,Qualification,Experience,array,pic_url,Teaching_mode,Distance,age,StudentEnquiryId})=>
          <Grid key={StudentEnquiryId}  item md={10} style={{boxShadow:' 0 5px 20px 2px rgba(0, 0, 0, 0.14)',margin: '25px 0px 25px', background: 'white'}}>
         <div>
<div style={{ justifyContent: 'space-around', display: 'flex',flexWrap: 'wrap', alignItems:'center'}}>
<div style={{textAlign:'center'}}>
    <img width={75} style={{border: '2px solid white',borderRadius:' 70px',     padding: '2px', background: '#5590c2'}}  src={pic_url} alt="" />
  
        <h3>{age} Years, {Gender}</h3>
        </div>

<div>
<h2>{Name}</h2>
<h4> {Contact_First}-XXXXXX-{Contact_Last}</h4>
</div>

<h3  style={{margin: '18px 6px 0',display: 'flex', color: '#5590c2'}}> <ExitToAppIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}} />  <b>Last Login</b>  <span style={{ color: '#272727',padding:' 0 5px'}}> : {Lastactivedate || 'Not Found'} </span></h3>


</div>

<div style={{ justifyContent: 'space-between', display: 'flex',flexWrap: 'wrap'}}>
          <div style={{textAlign: 'left',marginTop: '30px'}}>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}>   <BookIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}} />  <b> Qualification </b> : <span style={{ color: '#272727',padding:' 0 5px'}}> {Qualification} </span> </h3>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}>   <LocalMallIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}}  /> <b>Experience</b> : <span style={{ color: '#272727',padding:' 0 5px'}}> {Experience} Years</span></h3>

          </div>


          <div style={{textAlign: 'left',  maxWidth: '28em',marginTop: '30px'}}>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}> <FlightTakeoffIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}} /> <b>Distance</b>  <span style={{ color: '#272727',padding:' 0 5px'}}> :  {Distance}KM away </span></h3>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}> <PersonPinCircleIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}} /> <b>Area</b>  <span style={{ color: '#272727',padding:' 0 5px'}}>  : {Area} </span></h3>
          </div>
          </div>


          <div style={{}}>
          <h3 style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}> <LocalLibraryIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}}/>Subjects Preferred:</h3>
     {array.length && array.map(({subject,course},id)=>
     <Accordion key={id} style={{boxShadow:'none',    marginBottom: '8px'}} expanded={expanded === `panel${id}`} onChange={handleChange(`panel${id}`)}>
        <AccordionSummary style={{background: '#9f9f9f',color: 'white'}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
                <h3> {course}</h3>
        
        </AccordionSummary>
        <AccordionDetails style={{background: '#f1f1f1',    padding: '10px 0'}}>
        <ul style={{ listStyleType:'disc', fontWeight: '600'}}>
       { subject.map((data,id)=><li key={id}>{data}</li>)}
       </ul>
        </AccordionDetails>
       
      </Accordion>) }


    
          </div>




          <div style={{margin: '35px 0 20px'}}>
          <h3 style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}> <CastForEducationIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}}/>Teaching Modes :</h3>
       
          <ul style={{ listStyleType:'disc', fontWeight: '600',fontSize: '16px'}}>
       {Teaching_mode.length &&  Teaching_mode.map((data,id)=><li key={id}>{data}</li>)}
       </ul>
        </div>
          
  


         </div>
          </Grid>  
        )  }
       


          </Grid>
  
       
        </div>
    
    </Grid>  )
}


