import React,{ useState, useEffect } from 'react';
import axios from 'axios';
import './login.css';
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import img from '../../../src/images/logo.png';


export const SignIn = () => {


  const baseurl = process.env.REACT_APP_BASE_URL;

    let allstate = [ "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"];


    const [mes, setMsg] = useState();
    const [mes1, setMsg1] = useState();
    const [token, settoken] = useState([]);
    const [mes2, setMsg2] = useState();
    const [getclass, setclass] = useState([]);
    const [getboard, setboard] = useState([]);
    const [form, setform] = useState(true);
    const [form1, setform1] = useState(false);
    const [form2, setform2] = useState(false);


   const [val, setval] = useState({
       
       name:'',
       phone:'',
       class:'',
       email:'',

       board:'',
       state:'',
       city:'',
       school:'',

       otp:''
      
   });



    const myChange=(e) => {
    const {name, value}= e.target;
    setval(prev =>({ ...prev,  [name] : value}) );

    }
    
 
useEffect(()=>{

      const fetch = async () => {
  
        const res = await axios.get(`${baseurl}/OnlineCourseStudentLogin/getCourses`);
        setclass(res.data.courses);

        const res1 = await axios.get(`${baseurl}/OnlineCourseStudentLogin/getBoards`);
        setboard(res1.data.boards);
    
      }
  
     
      fetch();
  

    
    
  },[]);
  
    const fetch = async (event) => {
        
        event.preventDefault();
        

      const params = new URLSearchParams()
      params.append('name', val.name);
      params.append('course', val.class);
      params.append('phone', val.phone);
      params.append('email', val.email);
     
     
      const res = await axios.post(`${baseurl}/OnlineCourseStudentLogin/studentSignUpLanding`, params);
      setMsg(res.data.Message);
  if(res.data.Message==="successfully registered"){

    setform(false);
    setform1(true);

  }
        
      }



      const fetch1 = async (event) => {
        
        event.preventDefault();
        
    //   setform(false);
    //   setform1(true);

      const params = new URLSearchParams()
      params.append('name', val.name);
      params.append('phone', val.phone);
      params.append('board', val.board);
      params.append('city', val.city);
      params.append('school', val.school);
      params.append('state', val.state);
     
     
      const res = await axios.post(`${baseurl}/OnlineCourseStudentLogin/studentInfoLanding`, params);
    setMsg1(res.data);
    
    if(res.data.Message==="successfully registered"){
        setform1(false);
        setform2(true);
    
      }

    }
      const fetch2 = async (event) => {
        
        event.preventDefault();
        
    //   setform(false);
    //   setform1(true);

      const params = new URLSearchParams()
      params.append('otp', val.otp);
      params.append('sessionId', mes1.SessionId);
      params.append('otp_phone', val.phone);
     
     
     
      const res = await axios.post(`${baseurl}/OnlineCourseStudentLogin/verifyotp`, params);
     
      settoken(res.data.AccessToken);  
      setMsg2(res.data.result);
    
      }




if(mes2==="Matched"){
  localStorage.setItem("key", token);
  localStorage.setItem("phone", val.phone);
  return <Redirect to="/" />

  
}
  



      // localStorage.setItem("key", mes.AccessToken);
      // localStorage.setItem("phone", val.phone);
     
//       if (!localStorage.getItem("key") || localStorage.getItem("key") === "undefined") {
//         localStorage.setItem("phone", val.phone);
//         localStorage.setItem("key", mes.AccessToken);
      
//       }else{
// return <Redirect to="/" />

//       }


    return (


<div className="user_options-forms">

            
       {  (form===true) ? 
<div className="user_forms-login" style= {{ textAlign: 'center',  padding: '48px 35px'}}>
<img src={img} style={{width:'100px'}} alt="logo" />
<h2 className="forms_title" style={{fontWeight:'600', marginBottom: '19px'}}> GharPeShiksha </h2>
<h2 className="forms_title" style={{fontWeight:'600', fontSize: '16px'  }}>Education SImplified</h2>
<form className="forms_form"  autoComplete='off' onSubmit={fetch} >


  <fieldset className="forms_fieldset" style={{border: "none"}}>
    
    <div className="forms_field">
      <input minLength="2" value={val.name} onChange={myChange} type="text"  className="forms_field-input" placeholder="Enter Your Name" name="name" required />
    </div>

    <div className="forms_field" >
    <select value={val.class} onChange={myChange} name="class" className="forms_field-input" id="standard-select" required>
    <option value={''}>Select Class</option>
    {getclass.length && getclass.map((value,id) => (
          <option key={id} value={value}>{value}</option>
        
       
        ))}
</select>
    </div>
    

    <div className="forms_field">
      <input maxLength="10" value={val.phone} onChange={myChange} type="number"  className="forms_field-input" placeholder="Enter Your Phone Number" name="phone" required />
    </div>
  
  
    <div className="forms_field">
      <input onChange={myChange} value={val.email} type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" className="forms_field-input" placeholder="Enter your mail" name="email"  required />
    </div>
    
     
  </fieldset>
  <p style={{fontSize: '16px',     margin: '12px 0', color: 'red'}}> {(mes==="Account already exists") && "Account already exists"}</p>
          <div className="forms_buttons" align="center">
    <button type="submit"   className="forms_buttons-action">Next</button>
  </div>     
</form>

  <div className="forms_buttons" align="center">
  
   <p>Already have an account ? <Link to='/login'><span  style={{textDecorationLine:'underline'}}> Log in</span></Link></p>
  </div>

</div> :

(form1 === true) ?
//*************************************************
<div className="user_forms-login" style= {{ textAlign: 'center',  padding: '48px 35px'}}>
<img src={img} style={{width:'100px'}} alt="logo" />
<h2 className="forms_title" style={{fontWeight:'600', marginBottom: '19px'}}> GharPeShiksha </h2>
<h2 className="forms_title" style={{fontWeight:'600', fontSize: '16px'  }}>Education SImplified</h2>
<form className="forms_form"  autoComplete='off' onSubmit={fetch1} >


  <fieldset className="forms_fieldset" style={{border: "none"}}>
    
   
    <div className="forms_field" >
    <select value={val.board} onChange={myChange} name="board" className="forms_field-input" id="standard-select" required>
    <option value="">Select Board</option>

    {getboard.length && getboard.map((value,id) => (
          <option key={id} value={value}>{value}</option>
        
       
        ))}


</select>
    </div>



    <div className="forms_field" >
    <select value={val.state} onChange={myChange} name="state" className="forms_field-input" id="standard-select" required>
    <option value="">Select State</option>
  { allstate.map((value,id) => (
          <option key={id} value={value}>{value}</option>
        
       
        ))}

</select>
    </div>
    

    <div className="forms_field">
      <input value={val.city} minLength="2"  onChange={myChange} type="text"  className="forms_field-input" placeholder="Enter Your city" name="city" required />
    </div>

  
  
    <div className="forms_field">
      <input minLength="2" value={val.school}  onChange={myChange} type="text"  className="forms_field-input" placeholder="Enter Your school" name="school" required />
    </div>

    
     
  </fieldset>
          <div className="forms_buttons" style={{display:'flex'}} align="center">
          <p style={{cursor:'pointer'}} type="button" onClick={()=> { setform(true); setform1(false) }}   className="forms_buttons-action">Previous</p>  <button type="submit"   className="forms_buttons-action">Next</button>
  </div>     
</form>


</div> :

(form2 === true) ?
//*************************************************
<div className="user_forms-login" style= {{ textAlign: 'center',  padding: '48px 35px'}}>
<img src={img} style={{width:'100px'}} alt="logo" />
<h2 className="forms_title" style={{fontWeight:'600', marginBottom: '19px'}}> GharPeShiksha </h2>
<h2 className="forms_title" style={{fontWeight:'600', fontSize: '16px'  }}>Education SImplified</h2>
<form className="forms_form"  autoComplete='off' onSubmit={fetch2} >


  <fieldset className="forms_fieldset" style={{border: "none"}}>
    

    <div className="forms_field">
      <input value={val.otp} onChange={myChange} type="text"  className="forms_field-input" placeholder="Enter Your otp" name="otp" required />
    </div>

  
    
     
  </fieldset>
  <p style={{fontSize: '16px',     margin: '12px 0', color: 'red'}}> {(mes2==="Not Matched") && "Otp Not Correct"}</p>
  
          <div className="forms_buttons"  align="center">
          <button type="submit"   className="forms_buttons-action">Submit</button>
  </div>     
</form>


</div>  : null }
    
      </div>
    
    )


    
}