import React,{useEffect, useState} from 'react';
import {Typography, Grid, Divider, List, MenuItem, ListItem } from '@material-ui/core';
import {ListItemIcon,ListItemText, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import styles from './section.module.css';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import HelpIcon from '@material-ui/icons/Help';
import {Link} from 'react-router-dom';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import {Alert, Alert2} from './learn/popup';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import InfoIcon from '@material-ui/icons/Info';
import LocalLibraryRoundedIcon from '@material-ui/icons/LocalLibraryRounded';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import {useSelector, useDispatch} from 'react-redux';
import {fetchSuccess, offline} from '../../actions/actions';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  anc:{color: 'inherit',width:'100%',
  textDecoration: 'none'

  }

}));


const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    
  },
}))(MenuItem);

export const Sidebar  = () => {
  
  let phone = localStorage.getItem("phone");
  let tokenn = localStorage.getItem("key");
  const dispatch = useDispatch();
  
  const getData= useSelector(state=>state.userData);
  const getkey= useSelector(state=>state.data.recent_enqId);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClickOpen = () => {

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
    const sti={
        position: 'sticky',
        top: '75px',
        background:'white'
      }
      const anc={color: 'inherit',width:'100%',
      textDecoration: 'none'}
  
      useEffect(()=>{
       if(tokenn){
        dispatch(fetchSuccess(phone,tokenn));
        dispatch(offline(phone,tokenn));
      }
        
      },[])
      
      useEffect(()=>{

        if(getData.AccessTokenMatch==="False" ){

          return  handleClickOpen2();
   
               }
   
           
                 
      
      
      },[getData.AccessTokenMatch]);



   
   
 

return (

    <Grid  className={styles.sidebar}   item md={2}  style={{padding:' 12px 0'}}>
    <div  style={sti}>
    {(getData.name) ?  <Grid  style={{    background: 'rgb(85 144 194)', textAlign:'center'}} >
  
  <Link to="/"><img src={getData.image} width={100} style={{margin:' 20px 0 0px',border: '2px solid white',borderRadius:' 70px',  background: '#ececec'}}  alt='profile'/>  </Link>
  
   <div style={{padding: '20px 0'}}>
  <Typography style={{color:'white',textTransform: 'uppercase', fontWeight:600}} variant="h5" component="h2">
  Hello, {getData.name}
  </Typography>
  <Typography style={{color:'#f50057', fontWeight:600}} color="secondary" variant="subtitle1" component="h2">
  {getData.course} <span >{getData.board}</span>
  </Typography>
</div>

  </Grid> : 'loading....' }
   <Divider />
  <List  component="nav" aria-label="main mailbox folders">

    
  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
      <ListItem style={{padding:0}}>
            <ListItemIcon className={styles.log}>
              <SwitchVideoIcon />
            </ListItemIcon>
            <ListItemText primary="Online Video Classes" />
          </ListItem>
      
        
        </AccordionSummary>
        <Divider />
        <AccordionDetails style={{padding:0,flexDirection: 'column'}}>
        <Link style={anc} to='/'>
   
          <ListItem button>
         
            <ListItemIcon className={styles.log}> 
              <LocalLibraryIcon />
            </ListItemIcon>
            
            <ListItemText primary="Learn" />
          </ListItem>
          </Link> 

          <Link style={anc} to='/ask-your-doubt'> 
          <ListItem button>
            <ListItemIcon className={styles.log}>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Ask Your Doubts" />
          </ListItem>
          </Link>

          
		  <Link style={anc} to='/my-doubts'> 
          <ListItem button>
            <ListItemIcon className={styles.log}>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="My Doubts" />
          </ListItem>
          </Link>


          <Link  style={{ color: 'inherit',  textDecoration: 'none' }} to='/all-live-class'>
          <ListItem button> 
            <ListItemIcon className={styles.log}>
              <LiveTvIcon />
            </ListItemIcon>
            <ListItemText primary="Live Interactive Class" />
          </ListItem>
          </Link>

  
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
         <ListItem  style={{padding:0}}>
            <ListItemIcon className={styles.log}>
              <LocalLibraryRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="One to One Class" />
          </ListItem>
        
        </AccordionSummary>
         <Divider />
        <AccordionDetails style={{padding:0,flexDirection: 'column'}}>
        <Link style={anc} to='/my-requirement'>
   
          <ListItem button>
         
            <ListItemIcon className={styles.log}> 
              <AssignmentIcon />
            </ListItemIcon>
            
            <ListItemText primary="My Requirements" />
          </ListItem>
          </Link> 


          <Link  className={classes.anc} style={{ pointerEvents: (!getkey) && 'none'}}  to={{pathname: `/match-tutors/${getkey}`}} >
   
   <ListItem button>
  
     <ListItemIcon className={styles.log}> 
       <ImageSearchIcon />
     </ListItemIcon>
     
     <ListItemText primary="Matching Tutors" />
   </ListItem>
   </Link> 
   
   
    <Link className={classes.anc} style={{ pointerEvents: (!getkey) && 'none'}} to={`/responded-tutors/${getkey}`}>
   
   <ListItem button>
  
     <ListItemIcon className={styles.log}> 
       <LibraryAddCheckIcon />
     </ListItemIcon>
     
     <ListItemText primary="Responded Tutors" />
   </ListItem>
   </Link> 



        </AccordionDetails>
      </Accordion>
     

       
  
  
      
  
          <Link style={anc} to='/account'>
          <ListItem button>
            <ListItemIcon className={styles.log}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          </Link>


        
          <Link style={anc} to='/about-us'>
          <ListItem button>
            <ListItemIcon className={styles.log}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="About Us" />
          </ListItem>
          </Link>

   
    
          <ListItem button>
            <ListItemIcon className={styles.log}>
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText onClick={handleClickOpen}  primary="Logout" />
          </ListItem>
        </List>
        <Divider />
       <Alert2 op={open} cl={handleClose} /> 
       <Alert op={open2} cl={handleClose2} />
        </div>
    </Grid>
  
  
)


}



