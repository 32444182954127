/*eslint-disable */

import React from 'react';
import {Typography, Grid,  Card , CardContent, Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import styles from './box.module.css';


// const anc={color: 'inherit',
//   textDecoration: 'none'}





  export const Card1 = (prop) => {
    return (
    
       
     
       <Card className={''} style={{    boxShadow: '0 2px 7px 2px rgba(0, 0, 0, 0.14)',borderRadius: '24px'}}>
       
      <CardContent style={{padding: '20px 12px 10px'}}>
        <div>     <img src={prop.image} style={{ width: "65%",
  background: "#7abcbb",
  boxShadow: "0 -3px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  borderRadius: "100%"}} alt='subject' /> </div>
  
      <div style={{padding: '14px 15px 4px'}}>

      <Typography style={{ fontSize: '22px', background: "linear-gradient(to right, rgb(85 144 194), rgb(41 215 255))",
  color: "white",
  borderRadius: "55px",
  padding: "2px 0"}} variant="h5"   color="textSecondary" >
         {prop.sub}
        </Typography>
        <Typography style={{  fontWeight: '500',color: '#fc4a1a'}} variant="subtitle1"   color="textSecondary" >
         {prop.chap}
        </Typography>

      </div>
        
        
      </CardContent>
     
    </Card>
   
     
    )
}



export const ChapterCard = (prop) => {
  return (
  
      <Grid align='center'  item sm={12}>
      
      <Link style={{marginLeft:'auto', color: 'inherit', textDecoration: 'none'}}  to={prop.loc}> <Card className={''} style={{    boxShadow: '0 2px 7px 2px rgba(0, 0, 0, 0.14)'}}>
     
    <CardContent style={{display:'flex',alignItems: 'center',justifyContent: 'space-between'}}>
    <img className={styles.wid} src={prop.image} style={{width:'10%'}} alt='subject' />
    <div style={{margin: '0 12px'}}>
  <Typography className={styles.new} style={{textAlign:'left'}} variant="h5"   color="textSecondary" gutterBottom>
       {prop.name}
      </Typography>

      <Typography  style={{textAlign:'left', margin:'0'}}  variant="subtitle1"   color="textSecondary" gutterBottom>

       Chapter: {prop.chap}
      </Typography>
      <Typography  style={{textAlign:'left', margin:'0'}}  variant="subtitle1"   color="textSecondary" gutterBottom>

        {prop.listv} Video(s)
      </Typography>
      <p  style={{ margin:'0',textAlign:'left', color: '#f9976c',}}>{prop.inside} </p>
      </div>
      
    
        <Button id={styles.bt} style={{outline:'none',marginLeft: 'auto'}} variant="contained" color="primary">
      Start
</Button>
  
    </CardContent>
   
  </Card>
  </Link>

      </Grid>
   
  )
}




export const ChapterVideo = (prop) => {
  return (

      <Grid align='center'  item md={4}>
      
     <Card style={{boxShadow: '0 2px 7px 2px rgba(0, 0, 0, 0.14)'}} className={''}>
     
    <CardContent  style={{alignItems: 'center', padding:'0'}}>
    
        <img alt="vid" onClick={prop.onClick} className={styles.thumbnail} style={{  maxWidth:'21em',  borderTop: '0.5rem solid #484848', borderBottom: '0.5rem solid #484848', cursor:'pointer',maxHeight: '13em',objectFit: 'contain'}} src={prop.image} width="100%"   />
    
    
  <div style={{padding:'12px', color: 'white', background: 'rgb(6 82 176)'}}>
      <Typography   style={{fontWeight:'600'}}  align='left'  variant="subtitle1"  >
      {prop.title}<span style={{float:'right'}}> {prop.vis}</span>
      </Typography>
      
      <Typography align='left'  variant="subtitle2" >
      Duration : {prop.duration}
      </Typography>
    
      </div>
      
    
    </CardContent>
   
  </Card>
 

      </Grid>
    

  )
}




export const Pdf = () => {
  return (
  
    <Grid item md={12}>
    <Card className={''}>
        
        <CardContent  style={{display:'',alignItems: 'center', paddingBottom:'12px'}}>
     
        <embed src={""} style={{width : '100%', height:'500px'}}/>
        </CardContent>
       
      </Card>
         </Grid>


  )
}
