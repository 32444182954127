import React, { Component } from "react";
import cx from "classnames";

import Button from "./button";
import {SelectableAnswer} from "./select";



export default class QuizSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      choice: false,
      disabled: false,
      answerShow: false,
      hiding: false,
    
    };
  }

  handleSelected(e) {
    if (this.state.disabled === false) {
      this.setState({
        selected: e,
        disabled: true
      });
      this.setState({
        hiding: true
      });
      // Add a slight delay for showing the answer and pushing the selected state
      // We can use this to animate things!
      setTimeout(() => {
        this.setState({
          answerShow: true
        });
      }, 700);
      setTimeout(() => {
        this.setState({
          choice: true,
          hiding: false
        });
      }, 500);
    }
  }


  
  handleNext() {
      
    this.state.selected && this.props.pushAnswer();
    // Add a tiny delay to ensure we push the answer up before switching to the next
    // question/answer
    setTimeout(() => {
      this.props.next();
    }, 10);
  }
  


  render() {
    let node = React.createRef();
    const renderMath = () => {
      window.MathJax.Hub.Queue([
        "Typeset", 
        window.MathJax.Hub,
        node.current
     ]);
    }
    renderMath();

    const { selected, choice, hiding, answerShow } = this.state;

    const { item, number , len} = this.props;
    
// console.log(this.props);
// console.log(item);

    const { Question, Options, Answer, AnswerExplanation, McqImages } = item;
    
    
    return (
      <div   className="quiz__main">
        <div className="quiz__container container--xl mha">
        
            <div className={cx("px1 quiz__material question  mha")}>
              <span className="cw quiz__material_qstep">
                Question {number} of {len}
              </span>
             
             
              <h4
                className={cx("cw quiz__material_question", {
                  hiding: hiding
                })}
              > 
               <div style={{ borderBottom: '1px solid #e0e0e0',   fontSize: '18px', padding:' 20px 0',   fontWeight: '600',  color: '#5590c2',  textAlign: 'left'}}> Q{number}. {Question}  </div> 
                {(!McqImages.length) || McqImages.map((data, id) => <img alt="img" style={{display:'block', width:' 100%', margin:'0 auto',paddingBottom: '12px',maxWidth:'400px'}} key={id} src={data}  />)}
              </h4>
            </div>
         
          <div className="quiz__selections">
            <div className="quiz__selections_nest">

              <h3 style={{ fontWeight: '500',    textAlign: 'left'}}>OPTIONS</h3>
              <div style={{    width: '100%'}} className={cx("px1  jcc aic mha")}>
             
                {Options.map((singleChoice, id) => (
                  <SelectableAnswer
                    key={id}
                    topSelected={selected} // push selection back down
                   check={(id===0) ? "A"   : (id===1) ? "B" : (id===2) ? "C" : (id===3) ?  "D" : null}
                   
                    choice={singleChoice}
                    chech={Answer}
                    disabled={this.state.disabled}
                    size={singleChoice.fontSize}
                    onClick={e => this.handleSelected(e)}
                  />
                ))}
              </div>

              {choice && !hiding ? (
            <div className="px1 quiz__material anwser container--q mha">
             
              
              <div
                className={cx("quiz__material_answer", {
                  active: choice && answerShow
                })}
              >
                <div >
                  <h3 className="cw">{selected ? `Correct!` : `Nope`}</h3>
                  <div style={{    fontWeight: 500,overflowX: 'scroll'}}
                    className="cw"
                    dangerouslySetInnerHTML={{ __html: `Explanation:  ${AnswerExplanation}` }}
                  />
             
                </div>
              </div>
            </div>
          ) : null}


              
              <div className="f jcc aic">
                {choice && (
                  <div
                     className={cx("f jce quiz__options_next", {
                      visible: choice && answerShow
                    })}
                  >
                    <Button
                      classes={`f jcb cw`}
                      onClick={() => this.handleNext()}
                    >
                      {number === len ? `Calculate Score` : `Next Question`}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


