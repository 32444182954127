/*eslint-disable */
import React from 'react';
import {Foot} from './componens/footer';
import Section from './componens/section/section';
import { Grid, Container } from '@material-ui/core';
import {Nav1} from './componens/nav';
import {Teacher} from './componens/teacher/teacher-panel';
import {TeacherLive} from './componens/teacher/live-class';
import {TeacherLiveClass} from './componens/teacher/TeacherLive';
import {Side} from './componens/teacher/side';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import {Login} from './componens/login/login';
import {teacherLogin} from './componens/login/teacher';
import {TestUrl} from './componens/login/testurl';
import {SignIn} from './componens/signup/signup';


function App() {



  return (
    <div className="App">
 
 <Router>

      
<Switch>

<Route  exact  path='/login'  component={Login} />

<Route  exact  path='/url/:phone/:pass'  component={TestUrl} />
<Route  exact  path='/signup'  component={SignIn} />
<Route  exact  path='/teacher-login'  component={teacherLogin} />

<Route path="/teacher" render={(props) => (
  <>
<Nav1 />
    <div  style={{background:'#f2f2f2',marginTop: '12px',minHeight: '45em'}}>

    <Container maxWidth="xl">
        <Grid container  justify="center"  spacing={3}>
        <Side />
        <TeacherLive {...props} />
       
</Grid>


</Container>
  </div>
  </>
)}/>
   




   <Route path='/teacher-panel/:id/:subject/:title'  render={(props) => (
    <>
<Nav1 />
    <div  style={{background:'#f2f2f2',marginTop: '12px',minHeight: '45em'}}>

    <Container maxWidth="xl">
        <Grid container  justify="center"  spacing={3}>
        <Side />
        <Teacher {...props} />
       
</Grid>


</Container>
  </div>
  </>
  )}
/>   




<Route path='/live-interactive-class/:id/:subject/:title'  render={(props) => (
    <>
<Nav1 />
    <div  style={{background:'#f2f2f2',marginTop: '12px',minHeight: '45em'}}>

    <Container maxWidth="xl">
        <Grid container  justify="center"  spacing={3}>
    
        <TeacherLiveClass {...props} />
       
</Grid>


</Container>
  </div>
  </>
  )}
/>   



<Route component={Section}/>

  


</Switch>



</Router> 

    <Foot />

    </div>
  );
}




 





export default App;
