/* eslint-disable */


import React,{useState, useEffect} from 'react';
import {Typography, Grid, CircularProgress, Button,Card, CardMedia  } from '@material-ui/core';

import AvTimerIcon from '@material-ui/icons/AvTimer';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './video.js';

export const Video = ({ location, history}) =>{




return (
 

    <Grid  item xs={12} item md={10} >
    <div style={{background:'#e5fbf9', width:'100%', padding: '12px',minHeight: '22em'}} >
    <div style={{display:"flex"}}> 
   
    <Button onClick={() => history.goBack()} variant="outlined" color="primary" startIcon={<ArrowBackIcon />  }>
  Back 
</Button>



    </div>
    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
   
          </Typography>
         <Grid container spacing={5} item md={9}  style={{margin:'0 auto', width: '100%'}}>

          <Card style={{width:'100%'}}><CardMedia  style={{alignItems: 'center', padding:'0'}}>
          <video width="100%" controls>
  <source src={location.state.Url} type="video/mp4" />

</video>
         
    </CardMedia> 
    </Card> 
         <div style={ {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  background: "#006aa5",
  padding: "8px 11px 14px",
  marginBottom: "30px"
}}>
             <h1 style={{margin:0, color: 'white'}}><span style={{}}>{location.state.Title}</span></h1>
             {/* <h2 style={{margin:0, color: '#006aa5'}}>Duration : <span style={{}}> {location.state.Duration}</span></h2> */}
         </div>
 

          </Grid>
         
       
        </div>
      
    </Grid>
  )
}