import React,{ useState } from 'react';
import axios from 'axios';
import './login.css';
import {Redirect} from 'react-router-dom';
import img from '../../../src/images/logo.png';


export const teacherLogin = () => {

  const baseurl = process.env.REACT_APP_BASE_URL;

    const [mes, setMsg] = useState([]);
 
 
   const [val, setval] = useState({
       phone:'',
       password:''
   });

    const myChange=(e) => {
    const {name, value}= e.target;
    setval(prev =>({ ...prev,  [name] : value}) );



    }
    
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetch = async (event) => {
        
        event.preventDefault();
        const res = await axios.post(`${baseurl}/OnlineCourseTutorLogin/tutorLogin`, null,{
          signal:signal,
        
          params: {
              phone: val.phone,
              password:val.password
            }
        });

        
        
  
       
        setMsg(res.data);
        return function cleanup(){
          abortController.abort()
        }
  
        
      }


      const fetch1 = async (event) => {
        
      
        const res1 = await axios.post(`${baseurl}/OnlineCourseTutorDashboard/tutorInformation`, null,{
          signal:signal,
        
          params: {
              phone: val.phone,
           
            }
        });

        localStorage.setItem("tname", res1.data.TutorName);
        localStorage.setItem("timg", res1.data.TutorImage);
        console.log(res1);
  
       
      
        return function cleanup(){
          abortController.abort()
        }
  
        
      }
      
     
      // localStorage.setItem("key", mes.AccessToken);
      localStorage.setItem("tphone", val.phone);
      
      
      (mes.Message === 'Login successfull') && fetch1();
//       if (localStorage.getItem("tphone") === '') {
//         localStorage.setItem("phone", val.phone);
    
      
//       }else{
// return <Redirect to="/teacher" />

//       }
     
    
    return (


<div className="user_options-forms">
        <div className="user_forms-login" style= {{ textAlign: 'center',  padding: '48px 35px'}}>
         <img src={img} style={{width:'100px'}} alt="logo" /> 
         <h2 className="forms_title" style={{fontWeight:'600', marginBottom: '19px'}}> GharPeShiksha </h2>
          <h2 className="forms_title" style={{fontWeight:'600', fontSize: '16px'  }}>Education SImplified</h2>
          <form className="forms_form"  autoComplete='off' onSubmit={fetch} >
        

            <fieldset className="forms_fieldset" style={{border: "none"}}>
              
              <div className="forms_field">
                <input maxLength="10" value={val.phone} onChange={myChange} type="number"  className="forms_field-input" placeholder="Enter Your Phone Number" name="phone" required />
              </div>
			
			  <div className="forms_field">
                <input onChange={myChange} type="password" className="forms_field-input" placeholder="Enter Password" name="password"  required />
              </div>
              
               
            </fieldset>
                    <div className="forms_buttons" align="center">
              <button type="submit"   className="forms_buttons-action">login</button>
            </div>     
          </form>

			<div className="forms_buttons" align="center">
			
			{
(mes.Message === 'Login successfull') ?   <Redirect  status={301} to="/teacher" /> : <p style={{color:'red'}}>{mes.Message}</p> 
                
            }

            </div>
    
      </div>
    
      </div>
    
    )


    
}