/*eslint-disable */
import React,{useState, useEffect} from 'react';
import {Typography, Grid, CircularProgress, Card, CardContent, Button  } from '@material-ui/core';

import {Link} from 'react-router-dom';
import axios from 'axios';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

export const TeacherLive = (props) =>{

console.log(props);
    const anc={color: 'inherit',
  textDecoration: 'none'}

  let phone = localStorage.getItem("tphone");
  let tokenn = localStorage.getItem("key");

  const [getLive1, setLive1] = useState([]);
  const baseurl = process.env.REACT_APP_BASE_URL;





useEffect(()=>{
  const abortController = new AbortController();
  const signal = abortController.signal;
    const fetch = async () => {
  
    
      

       const res1 = await axios.post(`${baseurl}/OnlineCourseTutorDashboard/getLiveClassesSchedule`, null,{
        signal: signal,

        params: {
            phone: phone,
            // accessToken: tokenn
          }
      }); 

      console.log(res1.data)
 
      setLive1(res1.data.LiveNow)
    }
    fetch();

   

  return function cleanup(){
    abortController.abort()
  }

},[])


const reload =(ClassroomId, Subject, Title, Url)=>{


  props.history.push({pathname:`/live-interactive-class/${ClassroomId}/${Subject}/${Title}`, state:{Url}})
  window.location.reload();
}

return (
<>

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '0px',minHeight: '22em'}} >
    <div  style={{background:' #D0E9F2', padding:' 28px 0px'}} >

    {(!getLive1.length) ? null : 
<Typography  style={{padding:  "0px 12px 12px"}} variant="h4" component='h2'   gutterBottom>
  
  Live Now    
   </Typography>}
   {(!getLive1.length) ? null : getLive1.map(({TutorName,Title, Time, Image, Subject, Url, ClassroomId}, id)=> 


 <Grid align='center' key={id}  style={{display: "inline-block",     padding: '12px' }}  item sm={5}>
 <button style={{marginLeft:'auto', color: 'inherit', textDecoration: 'none'}}  onClick={()=>reload(ClassroomId, Subject, Title, Url)}> 
 <Card className={''} style={{    boxShadow: '0 2px 7px 2px rgba(0, 0, 0, 0.14)'}}>
 
 <CardContent style={{display:'flex',alignItems: 'center', flexWrap: "wrap",padding: ' 0px'}}>
   <div style={{padding: '0px',width:'100%'}}>
 <img  src={Image} style={{width:'100%',maxHeight: '12em'}} alt='subject' />
 </div>
 <div style={{width: '100%',    display: 'flex',justifyContent: 'space-between',padding: '6px 10px',  alignItems: 'center'}}>
   <h4 style={{margin:0}}>{Time}</h4>
   <h4 style={{margin:0, color: 'white', background: '#457e9e',padding: '2px 10px'}}> {Subject} </h4>
 </div>
 <div style={{ display: "flex",justifyContent: "space-between",width: "100%",padding: "8px 12px", background: "rgb(69 126 158)",  alignItems: 'center'}}> 
 <Typography  style={{    color: 'white',fontSize: '22px'}} variant="h5"   color="textSecondary" >
 
  {Title} 
 </Typography>
 

<PlayCircleFilledIcon style={{    color: 'white',      fontSize: '38px'   }} />

 </div>
  
 
 </CardContent>
 
 </Card>
 </button>
 </Grid>


)}
 </div>


 

   
       
        </div>
      


    </Grid>

    </>
    
    )
}


