/*eslint-disable */

import React,{useState} from 'react';
import {Sub} from './learn/sub';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import { Grid, Container } from '@material-ui/core';

import {Videopreview} from '../section/learn/videopreview';
import {Chapter} from '../section/learn/chapter';
import {Doubt} from '../section/doubt/doubt'
import {ChapterVideoP} from '../section/learn/chapterVideo';
import {Notes} from '../section/learn/notes';
import {MyDoubts} from '../section/doubt/mydoubts';
import {MyDoubtSeen} from '../section/doubt/mydoubtSeen';
import {Sidebar} from './sidebar';
import {Account} from '../section/account/accountset'; 
import {Nav} from '../nav';
import {Mcq1} from './learn/mcq/main';
import {LiveClass1} from './LiveClass';
import {About} from './about';
import {Past} from './allvideo/past';
import {Video} from './allvideo/vid';
import { Requirement } from './class/requirement';
import { MatchTutors } from './class/matchtutor';
import { RespondedTeacher } from './class/respondedtutors';
import { Profile } from './class/profile';


export default function Section() {


  return (
<>
<Nav />
    <div  style={{background:'#f2f2f2',marginTop: '12px',minHeight: '45em'}}>

    <Container maxWidth="xl">
        <Grid container  justify="center"  spacing={3}>
 

   <Route  exact  path='/' render={(props) => ( <> <Sidebar /> <Sub {...props} />  </> )}/>

  <Route exact  path='/chapter/:id'    render={(props) => ( <> <Sidebar /> <Chapter {...props}/> </> )}/>

  <Route exact path='/video/:name/:id' render={(props) => ( <> <Sidebar /> <ChapterVideoP {...props}/> </> )} />

  <Route  path='/ask-your-doubt' render={(props) => ( <> <Sidebar /> <Doubt {...props}/> </> )}    />

  <Route path='/account' render={(props) => ( <> <Sidebar /> <Account {...props}/> </> )}    />

  <Route exact path='/my-doubts' render={(props) => ( <> <Sidebar /> <MyDoubts {...props}/> </> )}   />

  <Route exact path='/my-doubtSeen/:id' render={(props) => ( <> <Sidebar /> <MyDoubtSeen {...props}/> </> )}   />

  <Route exact path='/video-preview/:name/:id' render={(props) => ( <> <Sidebar /> <Videopreview {...props}/> </> )} />

  <Route exact path='/video-preview/notes/:name/:id' render={(props) => ( <> <Sidebar /> <Notes {...props}/> </> )}  component={Notes} />

  <Route exact path='/quiz/:id' render={(props) => ( <> <Sidebar /> <Mcq1 {...props}/> </> )}   />

  <Route exact path='/live-class' render={(props) => ( <>  <LiveClass1 {...props}/> </> )}   />

  <Route exact path='/video' render={(props) => ( <> <Sidebar /> <Video {...props}/> </> )}  />

  <Route exact path='/about-us' render={(props) => ( <> <Sidebar /> <About {...props}/> </> )}  />

  <Route exact path='/my-requirement' render={(props) => ( <> <Sidebar /> <Requirement {...props}/> </> )}  />

  <Route exact path='/match-tutors/:id' render={(props) => ( <> <Sidebar /> <MatchTutors {...props}/> </> )}   />

  <Route exact path='/responded-tutors/:id' render={(props) => ( <> <Sidebar /> <RespondedTeacher {...props}/> </> )}   />

  <Route exact path='/all-live-class' render={(props) => ( <> <Sidebar /> <Past {...props}/> </> )}   />

  <Route exact path='/profile/:id/:enq' render={(props) => ( <> <Sidebar /> <Profile {...props}/> </> )}   />


 
  {/* <Redirect to='/' />     */}


       
</Grid>


</Container>
  </div>
    
</>
   
  );
}

