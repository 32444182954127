/*eslint-disable */
import React, { useEffect } from 'react';
import {Typography, Grid, Card, CardMedia, Button } from '@material-ui/core';






import Hls from 'hls.js';

function ReactHlsPlayer({
    autoplay = true,
    controls = false,
    hlsConfig = {},
    videoProps = {},
    playerRef = React.createRef(),
    style = {},
    width = '100%',
    height = 'auto',
    className,
    url,
    ...props
}) {
    useEffect(() => {
        let hls = null;

        function _initPlayer() {
            if (hls != null) {
                hls.destroy();
            }

            const newHls = new Hls({
                enableWorker: false,
                ...hlsConfig
            });

            newHls.attachMedia(playerRef.current);

            newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
                newHls.loadSource(url);

                newHls.on(Hls.Events.MANIFEST_PARSED, () => {
                    if (autoplay) {
                        playerRef.current.play();
                    }
                });
            });

            newHls.on(Hls.Events.ERROR, function (event, data) {
                if (data.fatal) {
                    switch(data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            newHls.startLoad();
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            newHls.recoverMediaError();
                            break;
                        default:
                            _initPlayer();
                            break;
                    }
                }
            });

            hls = newHls;
        }

        _initPlayer();

        return () => {
            if (hls != null) {
                hls.destroy();
            }
        };
    }, [autoplay, hlsConfig, playerRef, url]);

    return (
        <video
            className={className}
            style={style}
            ref={playerRef}
            controls={controls}
            width={width}
            height={height}
            {...videoProps}
            {...props}
        ></video>
    );
}

export default ReactHlsPlayer;




export const StudentiveClass = (prop) =>{

    console.log(prop)

const {ClassroomId} = prop.location.state;

  useEffect(() => {

screenShare(ClassroomId);

  
    // returned function will be called on component unmount 
    return () => {    };

  
  }, []);


return (
    

    <div style={{background:'aliceblue', width:'100%', padding: '12px',padding:'0'}} >
  

         <div style={{display:"flex",flexWrap: 'wrap'}}> 
   

     
            <Card style={{width:'100%',background: '#e4e4e4'}}>
      
 
         <article style={{minHeight: '50em', position: 'relative'}}>
        <div className="github-stargazers" />
        <section id="logs-message" className="experiment" style={{display: 'none', textAlign: 'center', fontSize: '1.5em', lineHeight: 2, color: 'red'}}>WebRTC getDisplayMedia API.</section>
        {/* // just copy this <section> and next script  */}
        <section className="experiment">
          <section className="hide-after-join" style={{textAlign: 'center'}}>                    
            <input type="text" id="room-name" placeholder="Enter " style={{width: '80%', textAlign: 'center', display: 'none'}} />
            <h2  style={{paddingTop: '14em'}}  color="primary">Wait For the Live Stream...</h2>
          </section>
       {/* // local/remote videos container  */}
          <div id="videos-container" />
          <section id="unique-token" style={{display: 'none', textAlign: 'center', padding: '20px'}} />
        </section>
        <section className="experiment"><small id="send-message" /></section>
      </article>


          </Card> 
      


         
          </div>


        </div>
      
        
 
  )
}





function screenShare(ClassroomId){
 

    
    
  let config = {
           
      openSocket: function(config) {
          

          let SIGNALING_SERVER = 'https://socketio-over-nodejs2.herokuapp.com:443/';
          config.channel = config.channel || ClassroomId;
          console.log(config.channel );
          let sender = ClassroomId;
           console.log(sender);
          io.connect(SIGNALING_SERVER).emit('new-channel', {
              channel: config.channel,
              sender: sender
          });

          let socket = io.connect(SIGNALING_SERVER + config.channel);
          socket.channel = config.channel;
          socket.on('connect', function () {
              if (config.callback) config.callback(socket);
          });

          socket.send = function (message) {
            console.log(message,"memememm");
              socket.emit('message', {
                  sender: sender,
                  data: message
              });
          };

          socket.on('message', config.onmessage);
      },
      onRemoteStream: function(media) {
          if(isbroadcaster) return;

          let video = media.video;
          videosContainer.insertBefore(video, videosContainer.firstChild);
          rotateVideo(video);

          document.querySelector('.hide-after-join').style.display = 'none';
      },
      onRoomFound: function(room) {
          if(isbroadcaster) return;

          console.log(room,"======================");
          
          if (typeof roomsList === 'undefined') roomsList = document.body;

        //   var tr = document.createElement('tr');
        //   tr.setAttribute('id', room.broadcaster);
        //   tr.innerHTML = '<td>' + room.roomName + '</td>' +
        //       '<td><button class="join" id="' + room.roomToken + '">Join Room</button></td>';
        //   roomsList.insertBefore(tr, roomsList.firstChild);
          captureUserMedia()
          conferenceUI.joinRoom({
              roomToken: room.roomToken,
              joinUser: room.broadcaster
          });

          document.querySelector('.hide-after-join').innerHTML = '<img src="https://www.webrtc-experiment.com/images/key-press.gif" style="margint-top:10px; width:50%;" />';
      },
      onNewParticipant: function(numberOfParticipants) {
          let text = numberOfParticipants + ' users are viewing your screen!';
          
          if(numberOfParticipants <= 0) {
              text = 'No one is viewing your screen YET.';
          }
          else if(numberOfParticipants == 1) {
              text = 'Only one user is viewing your screen!';
          }

          document.title = text;
          showErrorMessage(document.title, 'green');
      },
      oniceconnectionstatechange: function(state) {
          let text = '';

          if(state == 'closed' || state == 'disconnected') {
              text = 'One of the participants just left.';
              document.title = text;
              showErrorMessage(document.title);
          }

          if(state == 'failed') {
              text = 'Failed to bypass Firewall rules. It seems that target user did not receive your screen. Please ask him reload the page and try again.';
              document.title = text;
              showErrorMessage(document.title);
          }

          if(state == 'connected' || state == 'completed') {
              text = 'A user successfully received your screen.';
              document.title = text;
              showErrorMessage(document.title, 'green');
          }

          if(state == 'new' || state == 'checking') {
              text = 'Someone is trying to join you.';
              document.title = text;
              showErrorMessage(document.title, 'green');
          }
      }
  };


  let conferenceUI = conference(config);
  var startConferencing = document.getElementById('start-conferencing');
  var roomsList = document.getElementById('rooms-list');
    /* UI specific */
    let videosContainer = document.getElementById("videos-container") || document.body;
    function captureUserMedia(callback) {
        var video = document.createElement('video');
        video.setAttribute('autoplay', true);
        video.setAttribute('controls', true);
        participants.insertBefore(video, participants.firstChild);
        getUserMedia({
            video: video,
            onsuccess: function(stream) {
                config.attachStream = stream;
                callback && callback();
                video.setAttribute('muted', true);
                rotateVideo(video);
            },
            onerror: function() {
                alert('unable to get access to your webcam.');
                callback && callback();
            }
        });
    }

    function rotateVideo(video) {
        
    video.style.width ='100%';
        video.style[navigator.mozGetUserMedia ? 'transform' : '-webkit-transform'] = 'rotate(0deg)';
        setTimeout(function() {
            video.style[navigator.mozGetUserMedia ? 'transform' : '-webkit-transform'] = 'rotate(360deg)';
        }, 1000);
    }
  

}





  
  
    