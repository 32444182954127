/*eslint-disable */


export const userInfoReducer=(state=[], action )=>{

    switch(action.type){
     case 'getData':
// console.log(action,"action")
        return  action.payload.data ;

      default:
          return state;
    }

}

export const offlineData=(state=[], action )=>{

    switch(action.type){
     case 'getOffline':
// console.log(action,"action")
        return  action.payload.data ;

      default:
          return state;
    }

}



export const Reset=(state=[], action )=>{

    switch(action.type){
     case 'allReset':
// console.log(action,"action")
        return  state;

      default:
          return state;
    }

}
