/*eslint-disable */

import React,{useState, useEffect,useRef}  from 'react';
import {Typography, Grid} from '@material-ui/core';
import axios from 'axios';
import {Link} from 'react-router-dom';

import {Alert} from '../learn/popup';






export const MyDoubts = () =>{

  const baseurl = process.env.REACT_APP_BASE_URL;
    const anc={color: 'inherit',
  textDecoration: 'none'}

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };;


 const [set, setd]= useState([]);
    
 let phone = localStorage.getItem("phone");
 let tokenn = localStorage.getItem("key");
 
useEffect(()=>{
 
  const fetch = async () => {
    

  const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/myDoubt`, null,{

  
   
  
    params: {
        phone: phone,
       
        accessToken: tokenn
      }
  });
  
  if(res.data.AccessTokenMatch==="False" ){
    return  handleClickOpen();

         }
  setd(res.data.MyDoubts)
  
    
}
fetch();

  
},[]);







 
  

return (

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >

    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
   
    My Doubts 
          </Typography>
          <Grid style={{justifyContent: 'center', padding:'0 12px'}}>
          
          
         {(set.length) ? set.map(({Question, QuestionId, Description}, id)=>(
              <Link key={id}  style={ {color:'inherit', padding:'8px', width:'100%',display:'flex',justifyContent:'center',textDecoration:'none',margin:'5px'}} to={{pathname: `/my-doubtSeen/${QuestionId}`, state: {Question, Description} }}><Grid    item md={11} style={{boxShadow:' 1px 5px 15px #ddd' , width:"100%",   paddingBottom: '15px',marginBottom:'10px',marginTop:'20px',    borderRadius: '10px'}}>
              <Grid   item md={12} style={{background: '#eee',   padding: '10px', borderRadius:'10px 10px 0px 1px'}}>
          <p style={{fontSize:'14px',fontWeight:'500',margin:'0',textAlign:'right'}}><span style={{ }}>Answerd by: </span>
          <span>null, null</span></p> </Grid>     
             
              <Grid   item md={12} style={{  padding: '10px'}}>
          <p style={{fontSize:'18px',fontWeight:'600',margin:'0',color:'blue'}}>
           {Question}</p>
           <p style={{fontSize:'15px',fontWeight:'600',margin:'0',borderBottom:'1px solid #ccc',color:'#ccc'}}>
           {Description}</p>
            </Grid>
      </Grid> </Link>
         )): 'loading...'
       
  }
   
          </Grid>
  
       
        </div>
        <Grid item md={12}>
        <Alert op={open} cl={handleClose} />
    <br />
  </Grid>
    </Grid>)
}


