import {offlineData, userInfoReducer, Reset} from './userData';

import {combineReducers} from 'redux';




// const appReducer = combineReducers({
//     unauthorized: UnauthorizedErrorReducer,
//     first: FirstReducer,
//     second: SecondReducer,
//     third: ThirdReducer,
//   });
  

export const appReducer = combineReducers({

    userData: userInfoReducer,
    data:offlineData,
    Reset

})


export const allReducers = (state, action) => {
    if (action.type === 'allReset') {
      
      state = undefined;
    }
  
    return appReducer(state, action);
  };