/*eslint-disable */

import React,{useState, useEffect} from "react";
import {Typography, Grid, CircularProgress, Button,CardContent,Card } from '@material-ui/core';
import { Strider, Step } from "react-strider";
import cx from "classnames";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import "./style.css";
import axios from 'axios';

// import data from "./data";
import QuizSingle from "./single";
import {Test} from "./single";

export const  Mcq1 = (props) => {

  let node = React.createRef();


 
const [getmcq, setMcq] = useState([]);
const baseurl = process.env.REACT_APP_BASE_URL;
 const [open, setOpen] = React.useState(false);
 const [state, setState] = useState(0)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let tokenn = localStorage.getItem("key");
  let phone = localStorage.getItem("phone");
useEffect(()=>{

  
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetch = async () => {
    

      const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/getMcq`, null,{
        signal:signal,
      
        params: {
          
          mcqTitleId : props.match.params.id,
               accessToken: tokenn,
               phone:phone
          }
      });
      
      if(res.data.AccessTokenMatch==="False" ){
        return  handleClickOpen();
 
             }
      setMcq(res.data.McqList);
     
       console.log(res.data.McqList);
       
       
    }
    fetch();

    return function cleanup(){
        abortController.abort()
      }

   

},[]);


 

let score = state;
console.log(score);

    return (

         <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >
    <div> 
    <Button onClick={() => props.history.goBack()} variant="outlined" color="primary" startIcon={<ArrowBackIcon />  }>
  Back 
</Button>
    </div>
    <Typography  style={{padding: '12px',textAlign: 'center'}} variant="h4" component='h2'   gutterBottom>
    Multiple Choice Questions (MCQs)
          </Typography>
          {(!getmcq.length) ? 'Loading....' : 
        
          <Grid container spacing={3} style={{background:"#5f5f5f"}}>
      <div className="f jcc aic site custom">
        <div style={{    width: '100%'}}>
        
          
          {/* <h1>Multiple Choice Questions</h1> */}
          <Strider activeIndex={0} transitionSpeed={300}>
            {/* <Step>
              {({ next, goTo, active, hiding, activeIndex }) => (
                <div
                  className={cx("step__wrapper", {
                    "is-active": active,
                    "is-hiding": hiding
                  })}
                >
                  <QuizStart next={next} />
                </div>
              )}
            </Step> */}
            {/* <Step>
              {({ next, goTo, active, hiding, activeIndex }) => (
                <div
                  className={cx("step__wrapper", {
                    "is-active": active,
                    "is-hiding": hiding
                  })}
                >
                  <QuizSingle
                    {...props}
                    pushAnswer={() =>
                      setState({ correct: state.correct + 1 })
                    }
                    number={activeIndex}
                    next={next}
                    item={getmcq[0]}
                  />
                </div>
              )}
            </Step> */}

            {getmcq.map((dat, id)=>
              
               <Step key={id} >
             
              {({ next, goTo, active, hiding, activeIndex }) => (
                <div ref={node}
                  className={cx("step__wrapper", {
                    "is-active": active,
                    "is-hiding": hiding
                  })}
                >
                  
                  <QuizSingle 
                    {...props} 
                    len={getmcq.length}
                    pushAnswer={() => {     
          setState(prevActiveStep => prevActiveStep + 1)
                     }
                  }
                 
                    number={activeIndex + 1}
                    next={next}
                 
                    item={getmcq[id]}
                  />
           
                </div>
              )}
            
            </Step>
           
          )}


         
           {/*   <Step>
              {({ next, goTo, active, hiding, activeIndex }) => (
                <div
                  className={cx("step__wrapper", {
                    "is-active": active,
                    "is-hiding": hiding
                  })}
                >
                  <QuizSingle
                    {...props}
                    pushAnswer={() =>
                    setState({ correct: state.correct + 1 })
                    }
                    number={activeIndex}
                    next={next}
                    item={getmcq[activeIndex]}
                  />
                </div>
              )}
            </Step> */}
            <Step>
              {({ active, hiding, activeIndex }) => (
                <div
                  className={cx("step__wrapper", {
                    "is-active": active,
                    "is-hiding": hiding
                  })}
                >
                  <QuizEnd {...props} score={score} numb={getmcq.length} />
                </div>
              )}
            </Step>
          </Strider>
        </div>
      </div>
     
      </Grid>
   
      }
      </div>
      </Grid>
    );
  }


  // const  QuizStart = (props)=> {

  //   return (
  //     <div>
  //       <h4>Welcome to GharPeShiksha Quiz !</h4>
  //       <Button 
  //         onClick={() => props.next()}
  //       >
  //         Start Quiz
  //       </Button>
  //     </div>
  //   );
  // }


const  QuizEnd = ({ score, numb })=> {

    return (
      <div className="mha container--q">
{
(score > 0) ? <h4>You scored a {score+1}/{numb}!</h4> : <h4>You scored a {score}/{numb}!</h4>

}
        
    
      </div>
    );
  }

