
/*eslint-disable */
import React,{useState, useEffect} from 'react';
import {Typography,Card, CardMedia, Grid, CircularProgress } from '@material-ui/core';
import { ChapterVideo} from './box';
import {Link} from 'react-router-dom';
import axios from 'axios';
import HttpsIcon from '@material-ui/icons/Https';
import {Modal}  from './modal.jsx';
import img1 from '../../../images/teacher.png';
import img2 from '../../../images/video.png';
import img3 from '../../../images/doubt.png';
import img4 from '../../../images/teacherStudent.png';
import img5 from '../../../images/exam.png';
import img6 from '../../../images/test.png';
import coming from '../../../images/coming.jpg';
import {Alert} from './popup';

import {Notes} from './notes';


export const ChapterVideoP = ({match, history, location}) =>{

  const [open, setOpen] = React.useState(false);
 const [open2, setOpen2] = React.useState(false);
 const baseurl = process.env.REACT_APP_BASE_URL;
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
const anc={
    padding:'8px'
}


 
    
const [getVid, setVid] = useState([]);
const [getVid2, setVid2] = useState([]);
 let check = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
         

useEffect(()=>{


    const abortController = new AbortController();
    const signal = abortController.signal;
    let phone = localStorage.getItem("phone");
  let tokenn = localStorage.getItem("key");
    const fetch = async () => {
    

      const res = await axios.post(`${baseurl}/OnlineCourseStudentDashboard/getVideos`, null,{
        signal:signal,
       
        params: {
            phone: phone,
            chapterId :  match.params.id,
             accessToken: tokenn
          }
      });
      if(res.data.AccessTokenMatch==="False" ){
        return  handleClickOpen2();
 
             }

     setVid(res.data.VideoList);
     setVid2(res.data);
     
      //  console.log(res);
       
        
    }
    fetch();

    return function cleanup(){
        abortController.abort()
      }



},[]);



let subs= getVid2.Subscription;
console.log(subs);
// subs = 'true';

let store1=match.params.name;
let store2=match.params.id;




  
return (
  

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >
    <div> 
    <Link style={anc} to='/'> Subjects </Link> 
    <Link style={anc} to={`/chapter/${match.params.name}`}>Chapter</Link> 
    <p style={ {color:'inherit', padding:'8px', cursor: 'pointer',display: 'inline-block' }}  >Videos</p>
    </div>
    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
    {location.state.Chapter} 
          </Typography>



     <Notes
     chapId={match.params.id}
     
     first={
<>

<Grid container spacing={3} style={{justifyContent: 'center'}}>

          {getVid.length ? getVid.map(({VideoId, Title, Duration,Image,Views,contentType, VideoUrl, Visibility }, id)=>  <ChapterVideo key={id} 
           onClick={()=> {
            let mat = VideoUrl.match(check);

            if (contentType === "Video") {
              if (Visibility === "Public") {
          
         
          if(mat && mat[3]==="youtube.com"){
            history.push({pathname:`/video-preview/${match.params.name}/${VideoId}`, state:{VideoUrl, Visibility,store1, store2 }}) 
          
          }else{
            history.push({pathname:`/video-preview/${match.params.name}/${VideoId}`, state:{VideoUrl, Visibility,store1, store2 }}) 
          
          }
          
                  


            }else {
           if(subs === "false"){

            return  handleClickOpen();

        
            
          }else{
            history.push({pathname:`/video-preview/${match.params.name}/${VideoId}`, state:{VideoUrl, Visibility, store1, store2  }}) 

          }}}else{
            history.push({pathname:`/quiz/${VideoId}`});

          } }} image={Image} title={Title}  vis={(Visibility === "Private") ? <HttpsIcon /> : null} duration={Duration} />)
          : (getVid2.VideoList) ? 
         <Grid style={{margin:"0 auto"}} item md={6}> <Card style={{width:'100%'}}>
          <CardMedia  style={{alignItems: 'center', padding:'0'}}> 
         <img style={{width:"100%"}} src={coming} alt='coming' /> </CardMedia> </Card> </Grid> : <CircularProgress style={{position:'absolute',right: '40em',top:'15em'}} /> }
  
 
    
          </Grid>
  
</>



     }


          />





          
       
          <Modal tt={open} clo={handleClose}
            bene={'Upgrade Benefits'}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            img5={img5}
            img6={img6}

          />

<Alert op={open2} cl={handleClose2} />
        </div>
      
    </Grid>)
}



