import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import IconButton from '@material-ui/core/IconButton';
import {Checkbox,FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';


export  function Popup(prop) {




  return (
    <div>
   
      <Dialog
        open={prop.op}
        onClose={prop.cl}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to close this post?"}</DialogTitle>

        <DialogActions>
        <Button onClick={prop.cl} color="primary">
            Nope
          </Button>
          <Button onClick={prop.sub}  color="primary" autoFocus>
        Sure
          </Button>
        </DialogActions>
      </Dialog>

  
    </div>
  );
}




export  function Modal(prop) {




  return (
    <div>
   
      <Dialog
        open={prop.op}
        onClose={prop.cl}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{    background: '#5590c2', color: 'white'}} id="alert-dialog-title">{`Sending your Message to ${prop.name}`}  
        <IconButton aria-label="close"  onClick={prop.cl}>
          <CloseIcon style={{color:'white'}} />
        </IconButton></DialogTitle>
    
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <TextareaAutosize onChange={prop.change} style={{resize: 'none', height:'100px', width:'100%'}}
       rows={2}
       rowsMax={4}
      aria-label="maximum height"
      placeholder="enter your message (optional)"
  
    />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent:'center'}}>

       
          <Button style={{backgroundColor: '#5590c2'}} variant="contained" onClick={prop.sub}  color="primary" autoFocus>
        Submit
          </Button>
        </DialogActions>
      </Dialog>

  
    </div>
  );
}




export  function Repost(prop) {




  return (
    <div>
   
      <Dialog
        open={prop.op}
        onClose={prop.cl}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to Repost this post?"}</DialogTitle>

        <DialogActions>
        <Button onClick={prop.cl} color="primary">
            Nope
          </Button>
          <Button onClick={prop.sub}  color="primary" autoFocus>
        Yes
          </Button>
        </DialogActions>
      </Dialog>

  
    </div>
  );
}




export  const Editpost= (prop) =>{




  return (
    <div>
   
      <Dialog PaperProps={{ style: {margin: '0', width:' calc(100% - 15px)', maxWidth: '665px',maxHeight: 'calc(100% - 20px)'}}} fullWidth={true} maxWidth = {'md'} 
        open={prop.op}
        onClose={prop.cl}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{display:'flex', justifyContent: 'space-between', background: '#5590c2'}}>
        <DialogTitle style={{    color: 'white'}} id="alert-dialog-title">{`Edit your requirement`}  
        </DialogTitle>  <IconButton aria-label="close"  onClick={prop.cl}>
          <CloseIcon style={{color:'white'}} />
        </IconButton>

  </div>
        <DialogContent>
<div>

<div>
  
<h3 style={{margin: '18px 0 8px',fontWeight:'800!important'}}>Where you want to learn?</h3>
<FormControlLabel
        control={
          <Checkbox
            checked={prop.checka}
            value={"Home Tuition at Student's Home"}
            onChange={prop.handleChange}
            name="checkeda"
            color="primary"
          />
        }
        label="Home Tuition at My Home"
      />

<FormControlLabel
        control={
          <Checkbox
            checked={prop.checkb}
            value={"Home Tuition at Your Home"}
            onChange={prop.handleChange}
            name="checkedb"
            color="primary"
          />
        }
        label="Willing to travel to Teacher's Home"
      />

<FormControlLabel
        control={
          <Checkbox
            checked={prop.checkc}
            value={"Online Class"}
            onChange={prop.handleChange}
            name="checkedc"
            color="primary"
          />
        }
        label="Online Class (via Skype, Hangout etc)"
      />
</div>


          <div>
          <h3 style={{ margin: '18px 0 8px', fontWeight:'800!important'}}>Any Special Requirement</h3>
          <TextareaAutosize onChange={prop.change} style={{resize: 'none', height:'100px', width:'100%'}}
       rows={2}
       rowsMax={4}
      aria-label="maximum height"
      placeholder="enter your message (optional)"
  
    />
          </div>



          <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
            <div> 
              <h3 style={{ margin: '18px 0 8px', fontWeight:'800!important'}}>Alternate Contact</h3>
          <TextField
          id="outlined-number"
          label="Number"
          type="number"
          onChange={prop.change1} 
          variant="outlined"
        />
        </div>


        <div> 
              <h3 style={{ margin: '18px 0 8px', fontWeight:'800!important'}}>When you would like to start ?</h3>
              <TextField
          id="outlined-select-currency-native"
          select
        
          value={prop.text}
          onChange={prop.onChange}
          SelectProps={{
            native: true,
          }}
        
          variant="outlined"
        >
         
       
        <option value="Immediately">Immediately</option>
        <option value="Within next 2 weeks">Within next 2 weeks</option>
        <option value="Not sure, right now just checking prices">Not sure, right now just checking prices</option>
        
 
        
        </TextField>
        </div>
         
          </div>

          </div>
        </DialogContent>
        <DialogActions style={{justifyContent:'center'}}>

       
          <Button style={{backgroundColor: '#5590c2',margin: '8px 0'}} variant="contained" onClick={prop.sub}  color="primary" autoFocus>
        Update
          </Button>
        </DialogActions>
      </Dialog>

  
    </div>
  );
}