/*eslint-disable */

import React,{useState, useEffect}  from 'react';
import {Typography, Grid, CircularProgress,Button, Select } from '@material-ui/core';
import axios from 'axios';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Badge from '@material-ui/core/Badge';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from "@material-ui/core/styles";
import BookIcon from '@material-ui/icons/Book';
import CallIcon from '@material-ui/icons/Call';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';

const useStyles = makeStyles((theme) => ({
    badge: {
      right: -10
    }
  }));


export const RespondedTeacher = ({match, history}) =>{
    const classes = useStyles();

  const [data,  setdata] = useState([]);
  let phone = localStorage.getItem("phone");
  let tokenn = localStorage.getItem("key");
  const baseurl = process.env.REACT_APP_BASE_URL;
   const [result,  setresult] = useState();
    const anc={color: 'inherit',
  textDecoration: 'none'}



   
 
   
    

useEffect(()=>{
  const abortController = new AbortController();
  const signal = abortController.signal;

  
    const fetch = async () => {
  
      const params = new URLSearchParams()

      params.append('contact', phone)
      params.append('accessToken', tokenn)
      params.append('enq_id', match.params.id)
      //console.log(phone);
      const res = await axios.post(`${baseurl}/OfflineCourseStudentDashboard/respondedTutor`,params,signal);
   
       if(res.data.AccessTokenMatch==="True"){
        
        setresult(res.data.responded)
        if(res.data.responded==="Success"){
          setdata(res.data.responded_tutor);
        }
       
      }
    }



   
    fetch();

    return function cleanup(){
      abortController.abort()
    }
  
  
  
},[]);







const ss={
  padding: "5px 0"
}
  


return (

    <Grid  item xs={12} item md={10} >
    <div style={{background:'white', width:'100%', padding: '12px',minHeight: '22em'}} >

    <Typography  style={{padding: '12px'}} variant="h4" component='h2'   gutterBottom>
    Responded Tutors
          </Typography>

       

          <Grid container spacing={3} style={{justifyContent: 'center', backgroundColor: '#E4E9FD'}}>
         
         <div style={{width:'100%', background: '#5590c2',  textAlign: 'center', padding: '12px 8px',  marginBottom: '3em',}}>
             <h3 style={{    color: 'white'}}> {data.length} Responded Tutors</h3>
         </div>
         

         {  (result==="Error") ? <h1>Data Not found</h1> :  (!data.length) ? <CircularProgress style={{position:'fixed', top:'15em'}} /> : data.map(({tutor_id,tutor_last_login,Contact_First, tutor_distance,tutor_age,tutor_area,Contact_Last,tutor_name,StudentMessage,tutor_gender,tutor_pic,tutor_qualification,tutor_experience,tutor_contact,StudentEnquiryId }) => 
         <Grid  key={tutor_id} item md={10} style={{boxShadow:' 0 5px 20px 2px rgba(0, 0, 0, 0.14)',    padding: '0', margin: '0px 0px 25px', background: 'white'}}>
         
        
         <div style={{    padding: '8px 30px 30px'}}>
         <div style={{display: 'flex', justifyContent: 'space-between',flexWrap: 'wrap', alignItems: 'center'}}>

           <div style={{textAlign: 'center'}}>
           <img width={75} style={{margin:' 20px 0 0px',border: '2px solid white',  padding: '2px', background: '#5590c2'}} alt='' src={tutor_pic} />
        <p> <b style={{ fontSize: '18px'}}>{tutor_name}</b> </p>
        <p>{tutor_age} Years, {tutor_gender}</p>
          </div>



          <div style={{textAlign: 'left',marginTop: '30px'}}>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}>   <BookIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}} />  <b> Qualification </b> : <span style={{ color: '#272727',padding:' 0 5px'}}> {tutor_qualification} </span> </h3>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}>   <LocalMallIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}}  /> <b>Experience</b> : <span style={{ color: '#272727',padding:' 0 5px'}}> {tutor_experience} Years</span></h3>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}>   <CallIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}}  /> <b>Contact</b> : <span style={{ color: '#272727',padding:' 0 5px'}}>  {Contact_First}XXXXXX{Contact_Last} </span></h3>
          </div>


          <div style={{textAlign: 'left',  maxWidth: '28em',marginTop: '30px'}}>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}> <ExitToAppIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}} />  <b>Last Login</b>  <span style={{ color: '#272727',padding:' 0 5px'}}> : {tutor_last_login || 'Not Found'} </span></h3>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}> <FlightTakeoffIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}} /> <b>Distance</b>  <span style={{ color: '#272727',padding:' 0 5px'}}> :  {tutor_distance} KM away </span></h3>
          <h3  style={{margin: '10px 6px',display: 'flex', color: '#5590c2'}}> <PersonPinCircleIcon style={{margin: '0px 4px 0 0',padding: '0 2px'}} /> <b>Area</b>  <span style={{ color: '#272727',padding:' 0 5px'}}>  : {tutor_area} </span></h3>
          </div>
          </div>

        
         </div>

         <div style={{ display: 'flex', justifyContent: 'center', padding: '18px 16px',borderTop: "1px solid #9f9f9f"}}>

<Button onClick={()=>history.push(`/profile/${tutor_id}/${StudentEnquiryId}`)} style={{outline:'none',background: '#5590c2', margin: '6px'}} variant="contained" color="primary">
 View Profile
</Button>
</div>
                   </Grid> 
         )}
         
         

              


          </Grid>
  
       
        </div>
    
    </Grid>  )
}


