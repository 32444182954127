/*eslint-disable */
import React,{useState, useEffect} from 'react';
import {Typography,Card, Grid, CardMedia, CardContent, Button } from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';  
import SendIcon from '@material-ui/icons/Send';
import  {Reload1} from '../section/socket1';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import ForumIcon from '@material-ui/icons/Forum';
import AppBar from '@material-ui/core/AppBar';  
import Tabs from '@material-ui/core/Tabs';  
import Tab from '@material-ui/core/Tab';  
import {Link} from 'react-router-dom';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Box from '@material-ui/core/Box';  
import './vid.css'

function TabPanel(props) {  
  const { children, value, index, ...other } = props;  
  
  return (  
    <Typography  
      component="div"  
      role="tabpanel"  
      hidden={value !== index}  
      id={`simple-tabpanel-${index}`}  
      aria-labelledby={`simple-tab-${index}`}  
      {...other}  
    >  
      { <Box p={3}>{children}</Box>}  
    </Typography>  
  );  
}  
  
TabPanel.propTypes = {  
  children: PropTypes.node,  
  index: PropTypes.any.isRequired,  
  value: PropTypes.any.isRequired,  
};  
function testProps(index) {  
  return {  
    id: `simple-tab-${index}`,  
    'aria-controls': `simple-tabpanel-${index}`,  
  };  
}  





export  function TLive(props) {  
  const [value, setValue] = React.useState(0);  
  const [data, setdata]=useState([]);
  const [data1, setdata1]=useState();
  const {location, match} = props;
  const baseurl = process.env.REACT_APP_BASE_URL;

  const handleChange = (event, newValue) => {  
    setValue(newValue);  
 
   
  };  
console.log(props);


  // const [size, set] =useState(9);

  let name = localStorage.getItem("tname");

let phone = localStorage.getItem("tphone");
let tokenn = localStorage.getItem("key");
const [totalstudent, settotalstudent]=useState();
const [save, setsave]=useState({checked: false });


console.log(save);

useEffect(()=>{
  
  


  const abortController = new AbortController();
  const signal = abortController.signal;
    const fetch = async () => {
  



      const res0 = await axios.post(`${baseurl}/OnlineCourseTutorDashboard/getPublicChatStatus`, null,{
        signal:signal,
              
               params: {
                classroomId: match.params.id
                   
                 }
             });

             console.log(res0.data,'loadcstatus');
             if(res0.data.PublicChatStatus==='disable'){
              setsave({checked:true})
             }
 

      const params = new URLSearchParams()
      params.append('roomid', match.params.id)
     
     
      const res = await axios.post('https://live.gharpeshiksha.com/getChat', params);
      setdata(res.data)

            
                  
                    
                      
         

      const res1 = await axios.get(`https://live.gharpeshiksha.com/getCurrentUsersCount?roomid=${match.params.id}`);
      settotalstudent(res1.data)
      Reload1(res1.data);
    
    }
    fetch();
    
   
 
   
   
  return function cleanup(){
    abortController.abort()
  }

 
},[]);




async function Enable(){
const res = await axios.post(`${baseurl}/OnlineCourseTutorDashboard/setPublicChatStatus`, null,{
  // signal:signal,
        
         params: {
          classroomId: match.params.id,
          status : 'enable'
           }
       });
    console.log(res);
      }


      async function Disable(){
        const res = await axios.post(`${baseurl}/OnlineCourseTutorDashboard/setPublicChatStatus`, null,{
          // signal:signal,
                
                 params: {
                  classroomId: match.params.id,
                  status : 'disable'
                   }
               });
               console.log(res);

              }

let COLORS = [
  '#e21400', '#91580f', '#f8a700', '#f78b00',
  '#58dc00', '#287b00', '#a8f07a', '#4ae8c4',
  '#3b88eb', '#3824aa', '#a700ff', '#d300e7'
];
const getUsernameColor = (username) => {
  // Compute hash code
  let hash = 7;
  for (let i = 0; i < username.length; i++) {
     hash = username.charCodeAt(i) + (hash << 5) - hash;
  }
  // Calculate color
  let index = Math.abs(hash % COLORS.length);
  return COLORS[index];
}



const Set=(e)=>{
  setsave({checked: !save.checked }
    )
  
  if(save.checked==true){
  
    Enable()
  }else{

    Disable()
  }
}


  return (  
    <div>  
      <AppBar style={{background: 'white', color: "#181a1a", alignItems: 'center'}} position="static">  
        <Tabs value={value} onChange={handleChange}>
            
          <Tab label="Live Class " {...testProps(0)} />  
          <Tab label="Live Chat" {...testProps(1)} />  
      
        
        </Tabs>  
      </AppBar>  
      <TabPanel value={value} index={0}>  
       {props.first}
      </TabPanel>  

      <TabPanel value={value} index={1}>  

<>

<div style={{display:"flex",flexWrap: 'wrap'}}> 
  

     


  <Grid style={{margin:"0",width:'100%'}} item md={12}> 
  <Card style={{width:'100%', minHeight: '26em',position:'relative',height: '100%'}}>


<ul className="pages">
<li className="chat page">
<div className="chatArea">
<ul className="messages">
  <>

   <h4 style={{textAlign:'center'}}> <span className='total' >{totalstudent}</span>  Student Watching</h4>
   
  
   
{(!data.length) ? null : data.map((dat,id)=>
        <li key={id} className="message" >
          <span className="username" style={{color:getUsernameColor(dat.name)}} >{dat.name}</span>
          <span className="messageBody">{dat.message}</span></li>
        )}
</>
</ul>
</div>

<div>
<Button className="sendmsg"  variant="contained" type="button"  component="label"   startIcon={<SendIcon style={{marginLeft:'12px'}} />} style={{minWidth:'20%',padding:'17px 12px',boxShadow:'none',position:'absolute', bottom:'0', right:'0'}}>

</Button>
<input className="inputMessage" placeholder="Type here..."/>


</div>
</li>
<li className="login page">
<div className="form">
<h3 className="title">What's your nickname?</h3>
<input className="usernameInput" type="text" maxLength="14" defaultValue={name} />
<input hidden className="phoneInput" type="text" maxLength="14" defaultValue={phone} />
<input hidden className="roomInput" type="text" maxLength="14" defaultValue={match.params.id} />
</div>
</li>
</ul>

  </Card> 
  </Grid> 

  </div>

<div style={{    display: "flex",
  width: "fit-content",
  alignItems: "center",
  color: "white",
  margin: "18px auto 0px",
  background: "#5590c2",
  padding: "0px 14px 0"}}>
<h3>Chat Disable  </h3>
<li className="tg-list-item">
        
        <input className="tgl tgl-skewed" checked={save.checked}  onChange={Set} id="cb3" type="checkbox" />
        <label className="tgl-btn" data-tg-off="OFF" data-tg-on="ON" htmlFor="cb3"></label>
    </li>
{/* <Button className="enable" onClick={Enable}  variant="contained" type="button"  component="label"   startIcon={<ForumIcon style={{marginLeft:'12px'}} />} style={{minWidth:'20%',padding:'17px 12px',boxShadow:'none',background: '#5da4cc', color: 'white'}}>
Enable Chat
</Button>

<Button className="disable" onClick={Disable}  variant="contained" type="button"  component="label"   startIcon={<SpeakerNotesOffIcon style={{marginLeft:'12px'}} />} style={{minWidth:'20%',padding:'17px 12px',boxShadow:'none'}}>
Disable Chat
</Button> */}
</div>

</>
   
      </TabPanel>  


    </div>  
  );  
}  